import { createApp } from 'vue'
import { createStore } from "vuex"
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router'
import CKEditor from '@ckeditor/ckeditor5-vue';
import '@mdi/font/css/materialdesignicons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

//Custom CSS
import "./assets/css/style.css"
import "./assets/css/login-verfiy-flip.css"
import "./assets/css/main-menu.css"
import "./assets/scss/custom-css.scss"
import "./assets/css/main.css"
import "./assets/css/mobile-menu.css"
import "./assets/css/my_custom_css.css"

//Custom JS
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;
import './assets/js/bootstrap.min.js'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
})

const store = createStore({
    state: {
        show_spinner: false,
        show_progress: false,
        show_percentage: 0,
        captcha_val: [],
        narrative_arr: [],
        isMobile: false,
        per_page: 10,
        current_scope_id: '',
        current_server_message: {
            'pagecode': '',
            'status': '',
            'message': '',
        },
    },
    mutations: {
        showSpinner(state, val) {
            state.show_spinner = val;
        },
        showProgress(state, val) {
            state.show_progress = val;
        },
        showPercentage(state, val) {
            state.show_percentage = val;
        },
        setCaptcha(state, val) {
            state.captcha_val = val;
        },
        setNarrativeData(state, val) {
            state.narrative_arr = val;
        },
        isMobile(state, val) {
            console.log(val);
            if (window.innerWidth <= 1024) {
                state.per_page = 5;
                state.isMobile = true;
            } else {
                state.per_page = 10;
                state.isMobile = false;
            }
        },
        setCurrentScopeId(state, val) {
            state.current_scope_id = val;
        },
        setCurrentServerMsg(state, val) {
            state.current_server_message = val;
        }
    },
});

const app = createApp(App)
app.use(VueAxios, axios)
app.use(vuetify)
app.use(store)
app.use(CKEditor)
app.use(router)
app.mount('#app')
import axios from 'axios';
import { store } from '@/store/store.js';
const api_url = process.env.VUE_APP_SITE_URL;
var login_auth_token = '';
var premiumtoken = '';
if(localStorage.getItem('plrlogintoken') == null){
    login_auth_token = '';
}else{
    login_auth_token = localStorage.getItem('plrlogintoken');
}

if(localStorage.getItem('premiumtoken') == null){
    premiumtoken = '';
}else{
    premiumtoken = localStorage.getItem('premiumtoken');
}

export default {
    async getRequest($endpoint, $param = {}) {
        return await axios.get(process.env.VUE_APP_SITE_URL + $endpoint,
            {
                params: $param,
            }
        );
    },

    async postRequest($endpoint, $data = [], $headers = {} ) {
        return await axios({
            method: "post",
            url: api_url + $endpoint,
            data: $data,
            config: $headers,
        });
    },

    async postAuthRequest(endpoint, data = {}) {
        const config = {
            headers: { 
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + login_auth_token
            }
        };
        return await axios.post( 
            api_url + endpoint,
            data,
            config
        );
    },

    async validateUser($endpoint, $token, $data ) {
        return await axios.post(api_url + $endpoint, $data, {
            headers: {
                'Authorization': 'Bearer ' + $token,
            }
        });
    },

    async getHeaderRequest($endpoint, $data = [], $headers = {} ) {
        return await axios({
            method: "get",
            url: api_url + $endpoint,
            params: $data,
            config: $headers,
        });
    },

    async loginRequest($endpoint, $data = Array() ) {
        return await axios.post(process.env.VUE_APP_SITE_URL + $endpoint,
            {
                username: $data.username,
                password: $data.password,
                user_type: $data.user_type,
                rememberme: $data.rememberme,
                url: $data.url,
            }
        );
    },

    async signupRequest(endpoint, data = [] ) {
        var config = {};
        if(login_auth_token != ''){
            config = {
                headers: { 
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + login_auth_token
                },
                onUploadProgress: progressEvent => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    store.show_percentage = percentCompleted;
                    console.log("percentCompleted", percentCompleted);
                }
            }
        }else{
            config = {
                headers: { 
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: progressEvent => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    store.show_percentage = percentCompleted;
                    console.log("percentCompleted", percentCompleted);
                }
            }
        }
        
        return await axios.post( 
            api_url + endpoint,
            data,
            config
        );
    },

    async getAuthRequest($endpoint, $param = {}) {
        return axios.get(api_url + $endpoint, {
            params: $param,
            headers: {
                'Authorization': 'Bearer ' + login_auth_token,
            }
        });
    },

    async getBookRequest($endpoint, $param = {}) {
        return axios.get(api_url + $endpoint, {
            params: $param,
            headers: {
                'Authorization': 'Bearer ' + login_auth_token,
                'X-AUTH-TOKEN': premiumtoken,
            }
        });
    },

    async updateCalStatus(endpoint, data) {
        let headers = { 
            "Authorization": "Bearer " + login_auth_token
        };
        return await axios.post( 
            api_url + endpoint,
            data,
            headers
        );
    },

    async postPaymentRequest(endpoint, data = {}) {
        const config = {
            headers: { 
                "Authorization": "Bearer " + login_auth_token
            }
        };
        return await axios.post( 
            api_url + endpoint,
            data,
            config
        );
    }
}
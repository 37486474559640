
<template>
    <router-view />
</template>
<script>
import { onMounted, watch } from "vue";
import { useStore } from "vuex";
import { store } from "./store/store";

export default {
    setup() {
        const stores = useStore();
       
        onMounted(async () => {
            let current_url = window.location.pathname;
            if ( is_loggedin() && ("/user-registration" != current_url || "calculate-fee-estimate" != current_url) ) {
                await store.validate_user();
            }
        });

        store.is_show_message == watch(() => {
            if(store.is_show_message == true){
                setTimeout(function () {
                    store.is_show_message = false;
                }, 10000);
            }
        })

        const is_loggedin = () => {
            if (window.localStorage.getItem("plrlogintoken")) {
                let user_data = JSON.parse(
                    window.localStorage.getItem("user_data")
                );
                store.reg_data.is_user_logged_in = true;
                store.reg_data.logged_user_id = user_data.user_id;
                store.reg_data.logged_user_type = user_data.user_type;
                return true;
            }
            return false;
        };

        return {
            stores,
        }
    }
}
</script>

<style>
body {margin: 0;line-height: 1.5;text-align: left;background-color: #fff;font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;font-size: 14px !important;font-weight: normal !important;color: #6b6b6b !important;}
</style>

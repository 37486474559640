import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: () => import('../pages/HomePage.vue'),
        meta: {
            title: 'ICC Plan Review'
        }
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        component: () => import('../pages/AboutUs.vue'),
        meta: {
            title: 'About Us - ICC Plan Review'
        }
    },
    {
        path: '/contact-us',
        name: 'ContactUs',
        component: () => import('../pages/ContactUs.vue'),
        meta: {
            title: 'Contact Us - ICC Plan Review'
        }
    },
    {
        path: '/faqs',
        name: 'FaqPage',
        component: () => import('../pages/Faqs.vue'),
        meta: {
            title: 'FAQs - ICC Plan Review'
        }
    },
    {
        path: '/terms-conditions',
        name: 'TermsConditions',
        component: () => import('../pages/TermsConditions.vue'),
        meta: {
            title: 'Terms & Conditions - ICC Plan Review'
        }
    },
    {
        path: '/user-registration',
        name: 'RegistrationPage',
        component: () => import('../pages/Registration.vue'),
        meta: {
            title: 'Transmittal Request - ICC Plan Review'
        }
    },
    {
        path: '/calculate-fee-estimate',
        name: 'CalculateFeeEstimate',
        component: () => import('../pages/CalculateFeeEstimate.vue'),
        meta: {
            title: 'Calculate Fee Estimate - ICC Plan Review'
        }
    },
    {
        path: '/thank-you',
        name: 'ThankYou',
        component: () => import('../pages/ThankYou.vue'),
        meta: {
            title: 'Thank You - ICC Plan Review'
        }
    },
    {
        path: '/lost-password',
        name: 'LostPassword',
        component: () => import('../pages/LostPassword.vue'),
        meta: {
            title: 'Lost Password - ICC Plan Review'
        }
    },
    {
        path: '/dashboard',
        name: 'MainDashboard',
        component: () => import('../pages/dashboard/MainDashboard.vue'),
        meta: {
            title: 'Dashboard - ICC Plan Review',
            requiresAuth: true,
           
        }
    },
    {
        path: '/plr-fee-quote-template',
        name: 'PlrFeeQuoteTemplate',
        component: () => import('../pages/dashboard/PlrFeeQuoteTemplate.vue'),
        meta: {
            title: 'Plr Fee Quote Template - ICC Plan Review',
            requiresAuth: true
        }
    },
    {
        path: '/transmittal-requests-list',
        name: 'TransmittalRequestsList',
        component: () => import('../pages/dashboard/TransmittalRequestsList.vue'),
        meta: {
            title: 'Transmittal Requests - ICC Plan Review',
            requiresAuth: true
        }
    },
    {
        path: '/transmittal-request-details',
        name: 'TransmittalRequestDetails',
        component: () => import('../pages/dashboard/TransmittalRequestDetails.vue'),
        meta: {
            title: 'Transmittal Request Details - ICC Plan Review',
            requiresAuth: true
        },
        params: true
    },
    {
        path: '/edit-transmittal-request',
        name: 'EditTansmittalRequest',
        component: () => import('../pages/dashboard/EditTansmittalRequest.vue'),
        meta: {
            title: 'Transmittal Request - ICC Plan Review',
            requiresAuth: true
        },
        params: true
    },
    {
        path: '/plan-reviews-list',
        name: 'PlanReviewsList',
        component: () => import('../pages/dashboard/PlanReviewsList.vue'),
        meta: {
            title: 'Plan Reviews - ICC Plan Review',
            requiresAuth: true
        }
    },
    {
        path: '/plr-detail',
        name: 'PlanReviewDetail',
        component: () => import('../pages/dashboard/PlanReviewDetail.vue'),
        meta: {
            title: 'Plan Review Details - ICC Plan Review',
            requiresAuth: true
        }
    },
    {
        path: '/plan-rereviews-list',
        name: 'PlanReReviewsList',
        component: () => import('../pages/dashboard/PlanReReviewsList.vue'),
        meta: {
            title: 'Plan Rereviews - ICC Plan Review',
            requiresAuth: true
        }
    },
    {
        path: '/my-peer-reviews',
        name: 'MyPeerReviews',
        component: () => import('../pages/dashboard/MyPeerReviews.vue'),
        meta: {
            title: 'My Peer Reviews - ICC Plan Review',
            requiresAuth: true
        }
    },
    {
        path: '/fee-estimates-list',
        name: 'FeeEstimatesList',
        component: () => import('../pages/dashboard/FeeEstimatesList.vue'),
        meta: {
            title: 'Fee Estimates - ICC Plan Review',
            requiresAuth: true
        }
    },
    {
        path: '/fee-estimate-details',
        name: 'FeeEstimateDetails',
        component: () => import('../pages/dashboard/FeeEstimateDetails.vue'),
        meta: {
            title: 'Fee Estimate Details - ICC Plan Review',
            requiresAuth: true
        }
    },
    {
        path: '/invoices-list',
        name: 'InvoicesList',
        component: () => import('../pages/dashboard/InvoicesList.vue'),
        meta: {
            title: 'Invoices - ICC Plan Review',
            requiresAuth: true
        }
    },
    {
        path: '/payment/:id',
        name: 'PaymentDetail',
        component: () => import('../pages/dashboard/PaymentDetail.vue'),
        meta: {
            title: 'Payment Details - ICC Plan Review',
            requiresAuth: true
        },
        params: true
    },
    {
        path: '/customers',
        name: 'CustomersList',
        component: () => import('../pages/dashboard/CustomersList.vue'),
        meta: {
            title: 'Customers - ICC Plan Review',
            requiresAuth: true
        }
    },
    {
        path: '/account/:id',
        name: 'CustomersAccount',
        component: () => import('../pages/dashboard/CustomersAccount.vue'),
        meta: {
            title: 'Account - ICC Plan Review',
            requiresAuth: true
        },
        params: true
    },
    {
        path: '/user-additional-info',
        name: 'UserAdditionalInfo',
        component: () => import('../pages/dashboard/UserAdditionalInfo.vue'),
        meta: {
            title: 'User Additional Info - ICC Plan Review',
            requiresAuth: true
        },
        params: true
    },
    {
        path: '/payments-list',
        name: 'PaymentsList',
        component: () => import('../pages/dashboard/PaymentsList.vue'),
        meta: {
            title: 'Payments - ICC Plan Review',
            requiresAuth: true
        }
    },
    {
        path: '/reports',
        name: 'ReportPage',
        component: () => import('../pages/dashboard/ReportPage.vue'),
        meta: {
            title: 'Reports - ICC Plan Review',
            requiresAuth: true
        }
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('../pages/ResetPassword.vue'),
        meta: {
            title: 'Reset Password - ICC Plan Review',
        }
    },
    {
        path: '/plr-document-review-email',
        name: 'DocumentReviewEmailTemplate',
        component: () => import('../pages/dashboard/DocumentReviewEmailTemplate.vue'),
        meta: {
            title: 'Document Review Email - ICC Plan Review',
            requiresAuth: true
        }
    },
    {
        path: '/worksheet/:code/:year',
        name: 'WorksheetPage',
        component: () => import('../pages/worksheets/WorksheetPage.vue'),
        meta: {
            title: 'Worksheet - ICC Plan Review',
            requiresAuth: true
        },
        params: true
    },
    {
        path: '/edit-report',
        name: 'EditReport',
        component: () => import('../pages/dashboard/EditReport.vue'),
        meta: {
            title: 'Edit Report - ICC Plan Review',
            requiresAuth: true
        },
        params: true
    },
    {
        path: '/view-report',
        name: 'ViewReport',
        component: () => import('../pages/dashboard/ViewReport.vue'),
        meta: {
            title: 'View Report - ICC Plan Review',
            requiresAuth: true
        },
        params: true
    },
    {
        path: '/preview-report',
        name: 'PreviewReport',
        component: () => import('../pages/dashboard/PreviewReport.vue'),
        meta: {
            title: 'Preview Report - ICC Plan Review',
            requiresAuth: true
        },
        params: true
    },
    {
        path: '/:catchAll(.*)',
        name: 'PageNotFound',
        component: () => import('../pages/PageNotFound.vue'),
        meta: {
            title: 'Page not found - ICC Plan Review'
        }
    },
    {
        path: '/change-password',
        name: 'ChangePassword',
        component: () => import('../pages/dashboard/ChangePassword.vue'),
        meta: {
            title: 'Change Password - ICC Plan Review',
            requiresAuth: true,
           
        }
    },
    {
        path: '/view-pdf',
        name: 'ViewPdf',
        component: () => import('../pages/ViewPdf.vue'),
        meta: {
            title: 'View Pdf - ICC Plan Review',
        }
    },
    {
        path: '/oklahoma-state-fire-marshal',
        name: 'OklahomaStateFireMarshal',
        component: () => import('../pages/OklahomaStateFireMarshal.vue'),
        meta: {
            title: 'Oklahoma State Fire Marshal - ICC Plan Review',
        }
    },

    
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    if (to.meta.requiresAuth) {
        const token = localStorage.getItem('plrlogintoken');
        if (token) {
            next();
        } else {
            next('/');
        }
    } else {
        // Non-protected route, allow access
        next();
    }
});

export default router

import { reactive } from 'vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from 'moment';
import services from "@/services/HomeServices.js";
import axios from 'axios';
import router from '@/router';

export const store = reactive({
    is_home: true,
    is_header: false,
    show_skeleton: false,
    tab: { 1: true, 2: true, 3: true, 4: true },
    showForm: { 1: true, 2: false, 3: false, 4: false },
    showIcon: { 1: false, 2: false, 3: false, 4: false },
    isActive: { 1: true, 2: false, 3: false, 4: false },
    show_spinner: false,
    show_progress: false,
    show_percentage: 0,
    scope_type: '',
    show_inv_model: false,
    modal_type: 'small-popup-width',
    show_disclaimer: false,
    enable_agree: 0,
    $scope_arr: [],
    $scope_arr_pre: [],
    scope_text: '',
    show_checkpoint: false,
    show_narative: false,
    show_terms: false,
    files: [],
    scope_files: [],
    show_rereview_list: false,
    cancellation_reason: '',

    reg_data: {
        iccservice: "no",
        membership: "",
        c_membership: "",
        firm: "",
        telephone: "",
        fax: "",
        first_name: "",
        last_name: "",
        security_question: "",
        security_answer: "",
        email: "",
        ext: "",
        street: "",
        city: "",
        zipcode: "",
        password: "",
        c_password: "",
        invoice_membership: "",
        invoice_iccservice: "no",
        c_invoice_membership: "",
        inv_firm: "",
        inv_first_name: "",
        inv_last_name: "",
        inv_email: "",
        inv_telephone: "",
        inv_ext: "",
        inv_fax: "",
        inv_street: "",
        inv_city: "",
        inv_zipcode: "",
        captcha: "",
        fee_quote_requested: "",
        fee_estimate_request: false,
        fee_estimate_scopes: [],
        fee_estimate_data: [],
        x: "same_person",
        fee_id: '',
        crm_fee_id: '',
        document: "0",
        firm_disable: false,
        is_rereview: false,
        review_type: 'review',
        email_disable: false,
        last_disable: false,
        first_disable: false,
        logged_user_type: '',
        disable_field: false,
        is_staff_enable: false,
        approval_action_type: '',
        member_disable: '',
        states: '',
        country: '',
        inv_country: { crm_id: "5db4285a-8610-e911-80e9-005056bd15cd", country: "United States" },
        inv_state: '',
        inv_users: '',
        is_inv_contact_new: false,
        valid_inv_person: false,
        disabled_field: false,
        logged_user_id: '',
        is_assigned: '',
        is_user_logged_in: false,
        confirmed_edit_to_tr: false,
        parent_fee_id: '',
        is_hydraulically_val: false,
        invoice_users: [],
        scope_codes: [],
        scopes_info: [],
        project_name: '',
        project_street: '',
        project_city: '',
        project_zipcode: '',
        show_edit_fee_estimate: false,
        show_calculator_popup: true,
        show_verify: false,
        plr_status: '',
        project_states_list: [],
        project_country: { crm_id: "5db4285a-8610-e911-80e9-005056bd15cd", country: "United States" },
        project_state: '',
        document_list: '',
        hunter_review: 'no',
        po_number_opt: 0,
        po_number: '',
        narrative_arr: [],
        plrlogintoken: '',
        user: [],
        emailcontent: '',
        editemaildata: [],
        is_diff: ''
    },
    user: [],

    tab_visited: false,
    is_reload_captcha: false,
    show_fee_quotes: false,
    show_fee_quotes_edit: false,
    show_confirm_msg: false,
    page_title: '',
    show_document_view: false,
    submitt_buttun: 'Continue',
    showSetFeeModal: false,
    ready_to_load: false,
    cancellation_text_box: false,
    display_submit: true,
    t_and_c_checked: false,
    terms_condition: '',
    show_delete_doc: false,
    show_fee_message: false,
    showPOModal: false,
    showInvoicePopup: false,

    current_server_message: {
        'pagecode': '',
        'status': '',
        'message': '',
    },
    editor: ClassicEditor,
    editorConfig: {
        toolbar: [
            "Bold",
            "Italic",
            "NumberedList",
            "BulletedList"
        ],
        height: '400px',
    },
    info_active: {
        sr: false,
        sip: false,
        spen: false,
        req: false,
        apr: false,
        inv: false,
        pln: false,
        feepending: false,
        nw: false,
        doc: false,
        needapr: false,
        unass: false,
        ttl: false,
        inp: false,
        nrw: false,
        sbm: false,
        unpinv: false,
    },

    current_plr_id: '',
    current_scope_id: '',
    showDownloadFeeQuoteModal: false,
    fee_quote_data: {},
    showSetScopeFeeQuoteModal: false,
    showQuoteModal: false,
    RequestEditModal: false,
    show_bypass_modal: false,
    showViewDiffModal: false,
    showMailModal: false,
    showMailDiffModal: false,
    show_confirm_accept_decline_by_invoice: false,
    showDateRangeModal:false,
    showModal: false,
    showFeeQuoteTemplateModal: false,
    showPaymentVerifiedModal:false,
    showDocumentReviewModal: false,
    showDocumentReviewEmailModal: false,
    show_confirm_cancel_request: false,
    show_bypass_reason_modal: false,
    show_narrative_letters:false,
    confirmed_edit_to_tr: false,
    show_confirm_not_available: false,
    show_confirm_decline_by_invoice: false,
    show_confirm_cancel_request_customer: false,
    show_reports_documents: false,
    show_pending_fee: false,
    show_comment_box: false,
   

    dropdown_filters: {
        david_hunter_status: {
            0: "All Request",
            new_request: "New Request",
            cancelled: "Cancelled",
            need_approval: "Need Approval",
        },
        david_hunter_status_mob: [
            { key: "0", name: "All Request" },
            { key: "new_request", name: "New Request" },
            { key: "need_approval", name: "Need Approval" },
            { key: "cancelled", name: "Cancelled" }
        ],
        revision_status: {
            0: "All Revision Status",
            "need-approval": "Needs Approval",
            approved: "Approved",
            declined: "Declined"
        },
        revision_status_mob: [
            { key: "0", name: "All Revision Status" },
            { key: "need-approval", name: "Needs Approval" },
            { key: "approved", name: "Approved" },
            { key: "declined", name: "Declined" }
        ],
        assigned_status: {
            0: "Assigned and Unassigned",
            1: "Unassigned",
            2: "Assigned"
        },
        assigned_status_mob: [
            { key: "0", name: "Assigned and Unassigned" },
            { key: "1", name: "Unassigned" },
            { key: "2", name: "Assigned" }
        ],
        submitted_status: {
            0: "All Submission Dates",
            1: "Last Month",
            3: "Last 3 Month",
            6: "Last 6 Month",
            custom: "Custom Date Range"
        },
        submitted_status_mob: [
            { key: "0", name: "All Submission Dates" },
            { key: "1", name: "Last Month" },
            { key: "3", name: "Last 3 Month" },
            { key: "6", name: "Last 6 Month" },
            { key: "custom", name: "Custom Date Range" }
        ]
    },

    peer_reviewer_filters: {
        fee_status: {
            all: 'All Fee Status',
            set: 'Set',
            notset: 'Not Set'
        },
        report_status: {
            all: 'All Report Status',
            2: 'Pending Review',
            3: 'Reviewed',
            4: 'Submitted',
        },
        due_date_status: {
            all: 'All Due Dates',
            due_next_7: 'Due in next 7 days',
            due_next_30: 'Due in next 30 days',
            due_past_7: 'Due in past 7 days',
            due_past_30: 'Due in past 30 days',
            custom: 'Custom Date Range'
        },
        fee_status_mob: [
            { "key": 'all', "name": 'All Fee Status' },
            { "key": 'set', "name": 'Set' },
            { "key": 'notset', "name": 'Not Set' }
        ],
        report_status_mob: [
            { "key": 'all', "name": 'All Report Status' },
            { "key": '2', "name": 'Pending Review' },
            { "key": '3', "name": 'Reviewed' },
            { "key": '4', "name": 'Submitted' },
        ],
        due_date_status_mob: [
            { "key": 'all', "name": 'All Due Dates' },
            { "key": 'due_next_7', "name": 'Due in next 7 days' },
            { "key": 'due_next_30', "name": 'Due in next 30 days' },
            { "key": 'due_past_7', "name": 'Due in past 7 days' },
            { "key": 'due_past_30', "name": 'Due in past 30 days' },
            { "key": 'custom', "name": 'Custom Date Range' }
        ]
    },

    fee_estimate_filters: {
        all_fee_status: {
            applied: "Applied",
            cancelled: "Cancelled",
            pending: "Pending",
            all: "All Status"
        },
        all_fee_status_mob: [
          { key: "applied", name: "Applied" },
          { key: "cancelled", name: "Cancelled" },
          { key: "pending", name: "Pending" },
          { key: "all", name: "All Status" }
        ]
    },

    dropdown_options: {
        all_review: {
            processing_request: 'Processing Request',
            in_progress: 'In Progress',
            need_approval: 'Needs Approval',
            completed: 'Completed',
            cancelled: 'Cancelled',
            waiting_on_invoicee: 'Waiting on Invoicee',
            all: 'All Review Status'
        },
        all_review_mob: [
            { "key": 'processing_request', "name": 'Processing Request' },
            { "key": 'in_progress', "name": 'In Progress' },
            { "key": 'need_approval', "name": 'Needs Approval' },
            { "key": 'completed', "name": 'Completed' },
            { "key": 'cancelled', "name": 'Cancelled' },
            { "key": 'waiting_on_invoicee', "name": 'Waiting on Invoicee' },
            { "key": 'all', "name": 'All Review Status' }
        ],
        moderator_all_review: {
            in_progress: 'In Progress',
            completed: 'Completed',
            cancelled: 'Cancelled',
            need_approval: 'Needs Approval',
            processing_request: 'Processing Request',
            all: 'All Review Status'
        },
        moderator_all_review_mob: [
            { "key": 'in_progress', "name": 'In Progress' },
            { "key": 'completed', "name": 'Completed' },
            { "key": 'cancelled', "name": 'Cancelled' },
            { "key": 'need_approval', "name": 'Needs Approval' },
            { "key": 'processing_request', "name": 'Processing Request' },
            { "key": 'all', "name": 'All Review Status' }
        ],
        technical_all_review: {
            in_progress: 'In Progress',
            completed: 'Completed',
            all: 'All Review Status'
        },
        technical_all_review_mob: [
            { "key": 'in_progress', "name": 'In Progress' },
            { "key": 'completed', "name": 'Completed' },
            { "key": 'all', "name": 'All Review Status' }
        ],
        all_fee: {
            set: 'Set',
            not_set: 'Not Set',
            partially_set: 'Partially Set',
            all: 'All Fee Status'
        }, 
        all_fee_mob: [
            { "key": 'set', "name": 'Set' },
            { "key": 'not_set', "name": 'Not Set' },
            { "key": 'partially_set', "name": 'Partially Set' },
            { "key": 'all', "name": 'All Fee Status' }
        ],
        all_submitted: {
            all: 'All Submitted',
            last_month: 'Last Month',
            last_3_month: 'Last 3 Months',
            last_6_month: 'Last 6 Months',
            custom: 'Custom Date Range'
        },
        all_submitted_mob: [
            { "key": 'all', "name": 'All Submitted' },
            { "key": 'last_month', "name": 'Last Month' },
            { "key": 'last_3_month', "name": 'Last 3 Months' },
            { "key": 'last_6_month', "name": 'Last 6 Months' },
            { "key": 'custom', "name": 'Custom Date Range' }
        ],
        all_due_date: {
            all: 'All Due Dates',
            due_next_7: 'Due in next 7 days',
            due_next_30: 'Due in next 30 days',
            due_next_90: 'Due in next 90 days',
            due_past_7: 'Due in past 7 days',
            due_past_30: 'Due in past 30 days',
            due_past_90: 'Due in past 90 days',
            custom: 'Custom Date Range'
        },
        all_due_date_mob: [
            { 'key': 'all', 'name': 'All Due Dates' },
            { 'key': 'due_next_7', 'name': 'Due in next 7 days' },
            { 'key': 'due_next_30', 'name': 'Due in next 30 days' },
            { 'key': 'due_next_90', 'name': 'Due in next 90 days' },
            { 'key': 'due_past_7', 'name': 'Due in past 7 days' },
            { 'key': 'due_past_30', 'name': 'Due in past 30 days' },
            { 'key': 'due_past_90', 'name': 'Due in past 90 days' },
            { 'key': 'custom', 'name': 'Custom Date Range' }
        ],
        all_document_type: {
            uploaded: 'Attached With Request',
            send_mail: 'Send By Mail',
            submit_later: 'Submit Later',
            all: 'All Documents Types'
        },
        all_document_type_mob: [
            { 'key': 'uploaded', 'name': 'Attached With Request' },
            { 'key': 'send_mail', 'name': 'Send By Mail' },
            { 'key': 'submit_later', 'name': 'Submit Later' },
            { 'key': 'all', 'name': 'All Documents Types' }
        ]
    },
    alert_class:'',
    alert_message:'',
    is_show_message:'',
    dropdown_filters_customer: {
        all_inv_status: {
          paid_online: "Paid Online",
          paid_offline: "Paid Offline",
          processing: "Pending Approval",
          not_paid: "Not Paid",
          all: "All Invoice Status"
        },
        all_inv_status_mob: [
          { key: "paid_online", name: "Paid Online" },
          { key: "paid_offline", name: "Paid Offline" },
          { key: "processing", name: "Pending Approval" },
          { key: "not_paid", name: "Not Paid" },
          { key: "all", name: "All Invoice Status" }
        ]
    },
    dropdown_filters_gp: {
        all_gp_status: {
          na: "N/A",
          failed: "Failed",
          pending: "Pending",
          senttogp: "In Progress",
          verified: "Verified",
          all: "All GP Status"
        },
        all_gp_status_mob: [
          { key: "na", name: "N/A" },
          { key: "failed", name: "Failed" },
          { key: "pending", name: "Pending" },
          { key: "senttogp", name: "In Progress" },
          { key: "verified", name: "Verified" },
          { key: "all", name: "All GP Status" }
        ]
    },
       
    dropdown_filters_staff: {
        all_inv_status: {
          paid_online: "Paid Online",
          paid_offline: "Paid Offline",
          processing: "Pending Approval",
          in_process: "In-Process",
          not_paid: "Not Paid",
          all: "All Invoice Status"
        },
        all_inv_status_mob: [
          { key: "paid_online", name: "Paid Online" },
          { key: "paid_offline", name: "Paid Offline" },
          { key: "processing", name: "Pending Approval" },
          { key: "in_process", name: "In-Process" },
          { key: "not_paid", name: "Not Paid" },
          { key: "all", name: "All Invoice Status" }
        ]
    },

    payment_dropdown_filters: {
        all_pay_status: {
            paid: 'Paid',
            in_progress: 'In Progress',
            failed: 'Failed',
            all: 'All Payment Status'
        },
        all_mode_status: {
            online: 'Online',
            offline: 'Offline',
            all: 'All Payment Methods'
        },
        all_pay_status_mob: [
            { "key": 'paid', "name": 'Paid' },
            { "key": 'in_progress', "name": 'In Progress' },
            { "key": 'failed', "name": 'Failed' },
            { "key": 'all', "name": 'All Payment Status' }
        ],
        all_mode_status_mob: [
            { "key": 'online', "name": 'Online' },
            { "key": 'offline', "name": 'Offline' },
            { "key": 'all', "name": 'All Payment Methods' }
        ]
    },

    report_dropdown_options: {
        all_review: {
            pending: 'Pending',
            'processing-request': 'New',
            'in-progress': 'In Progress',
            completed: 'Completed',
            all: 'All Review Status'
        },
        fee_quote_request: {
            no: 'Not Requested',
            yes: 'Requested',
            all: 'All Fee Quote'
        },
        all_submitted: {
            all: 'All Submitted',
            last_month: 'Last Month',
            last_3_month: 'Last 3 Months',
            last_6_month: 'Last 6 Months',
            custom: 'Custom Date Range'
        },
        all_years: {
            all: 'All Years',
        },
        report_by: {
            month_wise: 'Month',
            discipline_wise: 'Discipline',
        },
        all_review_mob: [
            { "key": 'pending', "name":  'Pending'},
            { "key": 'processing-request', "name":  'New'},
            { "key": 'in-progress', "name":  'In Progress'},
            { "key": 'completed', "name":  'Completed'},
            { "key": 'all', "name":  'All Review Status'}
        ],
        all_years_mob: [
            { "key": 'all', "name": 'All Years'},
        ],
        report_by_mob: [
            { "key": 'month_wise', "name": 'Month'},
            { "key": 'discipline_wise', "name": 'Discipline'},
        ],
        all_months: {
            'all': 'All',
            '1': 'January',
            '2': 'February',
            '3': 'March',
            '4': 'April',
            '5': 'May',
            '6': 'June',
            '7': 'July',
            '8': 'August',
            '9': 'September',
            '10': 'October',
            '11': 'November',
            '12': 'December'
        },
        all_months_mob: [
            { "key": '1', "name":  'January'},
            { "key": '2', "name":  'February'},
            { "key": '3', "name":  'March'},
            { "key": '4', "name":  'April'},
            { "key": '5', "name":  'May'},
            { "key": '6', "name":  'June'},
            { "key": '7', "name":  'July'},
            { "key": '8', "name":  'August'},
            { "key": '9', "name":  'September'},
            { "key": '10', "name":  'October'},
            { "key": '11', "name":  'November'},
            { "key": '12', "name":  'December'}
        ],
    },

    getParameterByName: function(name, url){
        if (!url) url = window.location.href;
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    },

    format_date: function(date){
        if (!isNaN(date) && date > 0)
            return moment(date * 1000).format('MM-DD-YYYY');
        else
            return "-";
    },

    format_date_with_time: function (date) {
        if (!isNaN(date)) {
            return moment(date * 1000).format('MM-DD-YYYY hh:mm:ss A');
        } else
            return "-";
    },
    uppercase: function(name) {
        if(undefined != name){
            return name.toUpperCase();
        }
        return false;
    },

    capitalize: function(name) {
        const str_arr = name.split(' ')

        for(let i = 0; i < str_arr.length; i++){
            str_arr[i] = str_arr[i][0].toUpperCase() + str_arr[i].slice(1)
        }
        return str_arr.join(' ');
    },

    format_amount: function(amount) {
        return '$'+ amount;
    },

    format_amount_price: function(amount) {
        let amt_val = parseFloat(amount);
        if (!isNaN(amt_val)) {
            let amt = amt_val.toFixed(2);
            amt_val = '$'+ amt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
         
        }else{
            amt_val = '$'+amt_val;
        }
        return amt_val;
    },

    s_options: {
        na: "NA",
        ok: "OK",
        nc: "NC",
        ncok: "NC-OK",
        nr: "NR",
        nrna: "NR/NA",
        oknr: "OK/NR",
        no: "No",
        yes: "Yes",
        pass: "Pass",
        fail: "Fail",
        reviewed: "Reviewed",
        add_comment: "Add Comment",
        add_note: "Add New Note",
        link_note: "Link to a Note"
    },

    auto_options: {
        na: "NA",
        ok: "OK",
        nc: "NC",
        ncok: "NC-OK",
        nr: "NR",
        nrna: "NR/NA",
        oknr: "OK/NR",
        reviewed: "Reviewed",
    },

    data_obj: {
        tag_left_pos: "-2000px",
        tag_top_pos: "-3000px",
        staff_users: [],
        current_tag_el: "",
        tooltip: [],
        worksheet_title: "",
        worksheet_last_edit: [],
        parent_request_id: "",
        scrolled: false,
        expand_details: false,
        hide_left: false,
        hide_right: false,
        box_left: "-1000px",
        box_top: "-1000px",
        current_element: null,
        auto_suggest_opened: false,
        selection_start: 0,
        asg_current_key: 0,
        selected_element: null,
        selected_ws_op: 0,
        selected_ws_key: "na",
        box_left_c: "-1000px",
        box_top_c: "-1000px",
        note_type: "n",
        comnet_box_title: "Your comments here.",
        comment_text: "",
        to_validate: false,
        field_id: "",
        hide_dbox: true,
        hide_cbox: true,
        hide_ltn_box: true,
        reply_comments: [],
        comment_reply_flag: [],
        reply_comment_id: "",
        comments: [],
        field_comments: [],
        full_comments: [],
        total_notes: [],
        note_numbers: [],
        notes: [],
        CODE: { worksheet_title: ""},
        ltn_box_left_c: "-1000px",
        ltn_box_top_c: "-1000px",
        note_to_link_global: "",
        option_list_top: 34,
        initail_window_height: window.innerHeight,
        center_panle_height: window.innerHeight - 280,
        right_left_panle_height: window.innerHeight - 300,
        wsDetailsPanelHeight: 0,
        show_spinner: false,
        edit_cancel: {},
        mouse_on_page: { 1: false, 2: false },
        active_el: 1,
        full_notes: [],
        note_comment_text: "",
        user_type: "",
        report_status: "",
        ws_status: "",
        auto_option: {},
        note_delete_c: false,
        cannot_be_edited: false,
        note_data_delete: {},
        comment_delete_c: false,
        comment_data_delete: {},
        prevActionTime: new Date().getTime(),
        unlink_confirm: false,
        unlink_field: false,
        unlink_field_note_id: false,
        showSetFeeModal: false,
        showSetScopeFeeQuoteModal: false,
        scope_fee: {},
        scope_fee_quote_data: {},
        scopeId: null,
        plr_id: null,
        page_id: "worksheet",
        submit_ws: false,
        submit_ws_value: 1,
        all_comments_notes: false,
        request_id_pdf: "",
        pending_fee_quote_approval: false,
        plr_status: "",
        clause_popup: false,
        premium_data: {},
        book_version: [],
        premium_response: '',
        premium_clause: '',
        calculate_status: true,
        show_caculator_box: false,
        hide_caculator_box: false,
        show_wrong_box: false,
        new_premium_api_url: 'https://api.iccsafe.org',
        new_premium_api_version: 'v1',
        left_panel: false,
        right_panel: false,
        pages: 0,
        year: 0,
        title: '',
        field_name: '',
        window_ws_data: '',
        scope_detail_id: '',
        note_edit: false,
        comment_edit: false,
        sections_arr: [],
        pdf_url: '',
        popup_msg: 'Report has been submitted by peer reviewer. Worksheet cannot be edited.'
    },

    getWindowHeight: function() {
        let diff = store.data_obj.initail_window_height - window.innerHeight;
        store.data_obj.initail_window_height = window.innerHeight;
        store.data_obj.center_panle_height -= diff;
        store.data_obj.right_left_panle_height -= diff;
    },

    inputClass: function (input_class) {
        let inputClass = '';
        if (typeof input_class != "undefined") {
            if (typeof input_class !== "number" && typeof input_class !== "object" ) {
                inputClass = input_class.toUpperCase();
            } else {
                inputClass = input_class;
            }
            if (input_class == "") {
                inputClass = "yellow-worksheet";
            } else if (input_class == "NA") {
                inputClass = "gray-worksheet";
            } else {
                inputClass = "red-worksheet";
            }
        }

        return "form-control " + inputClass;
    },

    hide_option_box_on_type: function (event) {
        console.log(event);
        store.hide_ws_option_box();
    },

    hide_ws_option_box: function () {
        store.data_obj.box_left = "-1000px";
        store.data_obj.box_top = "-1000px";
        store.data_obj.hide_dbox = true;
    },

    view_option_box: function (el) {
        if (store.data_obj.calculate_status) {
            //return false;
        }
        store.data_obj.all_comments_notes = false;
        store.data_obj.current_element = el;
        if (store.data_obj.current_element.target.getAttribute("data-type") == "radio") {
            store.data_obj.auto_option = { yes: "Yes", no: "No" };
        } else if (
            store.data_obj.current_element.target.getAttribute("data-type") == "radio-pass-fail") {
            store.data_obj.auto_option = { pass: "Pass", fail: "Fail" };
        } else {
            store.data_obj.auto_option = store.auto_options;
        }
        var target_name = store.data_obj.current_element.target.name;
        target_name = target_name.split("[").join("['");
        target_name = target_name.split("]").join("']");
        store.get_worksheet_notes(target_name);
        store.get_worksheet_comments(target_name);
        
        if (
            store.data_obj.pending_fee_quote_approval || store.data_obj.plr_status == "cancelled" ||
            store.data_obj.report_status == "4" ||
            store.data_obj.parent_request_id != "0"
        ) {
            el.target.setAttribute("readOnly", true);
            el.target.setAttribute("disabled", true);
        } else {
            el.target.focus();
            var rect = store.data_obj.current_element.target.getBoundingClientRect();
            var offsetHeight = document.getElementById("wp_auto_options_id").offsetHeight;
            if (offsetHeight <= "302") {
                offsetHeight = "302";
            }
            var from_top = store.top_dist(document.getElementById("colophon")) - rect.top - store.data_obj.current_element.target.offsetHeight;
            store.data_obj.box_left = rect.left + store.data_obj.current_element.target.clientWidth - document.getElementById("wp_auto_options_id").clientWidth + "px";
            if (
                rect.left +
                store.data_obj.current_element.target.clientWidth <
                document.getElementById("wp_auto_options_id").clientWidth
            ) {
                store.data_obj.box_left = rect.left + "px";
            }
            var tp = offsetHeight - from_top + 5;
            if (offsetHeight > from_top) {
                document.getElementById("mid-div").scrollTop += tp;
                store.data_obj.box_top = rect.top +
                store.data_obj.option_list_top -
                store.data_obj.current_element.target.clientHeight +
                store.data_obj.current_element.target.offsetHeight + "px";
            } else {
                store.data_obj.box_top = rect.top + store.data_obj.option_list_top + "px";
            }

            store.data_obj.hide_dbox = false;
            store.data_obj.selected_ws_key = "na";
            store.data_obj.selected_ws_op = 0;
            store.data_obj.box_left_c = "-1000px";
            store.data_obj.box_top_c = "-1000px";
            store.data_obj.selected_element = null;
            store.data_obj.comment_text = "empty";

            store.data_obj.field_id = target_name;
            store.hide_link_to_a_note();
        }
        store.submit_worksheet();
    },

    view_note_box: function (el) {
        console.log("clicked view_note_box")
        store.data_obj.all_comments_notes = false;
        store.data_obj.current_element = el;
        if (store.data_obj.calculate_status) {
            store.data_obj.auto_option = '';
        } else {
            if (
                store.data_obj.current_element.target.getAttribute("data-type") ==
                "radio"
            ) {
                store.data_obj.auto_option = { yes: "Yes", no: "No" };
            } else if (
                store.data_obj.current_element.target.getAttribute("data-type") ==
                "radio-pass-fail"
            ) {
                store.data_obj.auto_option = { pass: "Pass", fail: "Fail" };
            } else {
                store.data_obj.auto_option = store.auto_options;
            }
        }
        var target_name = store.data_obj.current_element.target.name;
        target_name = target_name.split("[").join("['");
        target_name = target_name.split("]").join("']");
        store.get_worksheet_comments(target_name);
        store.get_worksheet_notes(target_name);
        if (
            store.data_obj.pending_fee_quote_approval || store.data_obj.plr_status == "cancelled" ||
            store.data_obj.report_status == "4" ||
            store.data_obj.parent_request_id != "0"
        ) {

            el.target.setAttribute("readOnly", true);
            el.target.setAttribute("disabled", true);
        } else {
            el.target.focus();
            var rect = store.data_obj.current_element.target.getBoundingClientRect();
            var offsetHeight = document.getElementById(
                "wp_auto_options_id"
            ).offsetHeight;
            if (offsetHeight <= "302") {
                offsetHeight = "302";
            }
            var from_top =
                store.top_dist(document.getElementById("colophon")) -
                rect.top -
                store.data_obj.current_element.target.offsetHeight;

            store.data_obj.box_left =
                rect.left +
                store.data_obj.current_element.target.clientWidth -
                document.getElementById("wp_auto_options_id")
                    .clientWidth +
                "px";
            if (
                rect.left +
                store.data_obj.current_element.target.clientWidth <
                document.getElementById("wp_auto_options_id")
                    .clientWidth
            ) {
                store.data_obj.box_left = rect.left + "px";
            }
            var tp = offsetHeight - from_top + 5;
            if (offsetHeight > from_top) {
                document.getElementById("mid-div").scrollTop += tp;
                store.data_obj.box_top =
                    rect.top +
                    store.data_obj.option_list_top -
                    store.data_obj.current_element.target.clientHeight +
                    store.data_obj.current_element.target.offsetHeight +
                    "px";
            } else {
                store.data_obj.box_top =
                    rect.top + store.data_obj.option_list_top + "px";
            }

            store.data_obj.hide_dbox = false;
            store.data_obj.selected_ws_key = "na";
            store.data_obj.selected_ws_op = 0;
            store.data_obj.box_left_c = "-1000px";
            store.data_obj.box_top_c = "-1000px";
            store.data_obj.selected_element = null;
            store.data_obj.comment_text = "empty";
            store.data_obj.field_id = target_name;
            store.hide_link_to_a_note();
        }
    },

    top_dist: function (elem) {
        var location = 0;
        if (elem.offsetParent) {
            do {
                location += elem.offsetTop;
                elem = elem.offsetParent;
            } while (elem);
        }
        return location >= 0 ? location : 0;
    },

    hide_link_to_a_note: function () {
        store.data_obj.ltn_box_left_c = "-1000px";
        store.data_obj.ltn_box_top_c = "-1000px";
        store.data_obj.hide_ltn_box = true;
    },

    mouse_over_WS: function (el) {
        var e = el.currentTarget;
        var ws_year = store.data_obj.CODE.year;
        var year_page = "page_" + ws_year;
        if ( !!e.dataset[year_page] && e.dataset[year_page] != "undefined" ) {
            if (store.data_obj.active_el != e.dataset[year_page]) {
                store.data_obj.active_el = e.dataset[year_page];
                var taggetId = parseInt(e.dataset[year_page]) + 1;
                var elem = document.getElementById(
                    "thumb_" + e.dataset[year_page]
                );
                var rect = elem.getBoundingClientRect();
                console.log(rect, elem, taggetId);
                if (store.data_obj.hide_left != true) {
                    elem.scrollIntoView(false);
                }
            }
        }
    },

    do_submit_worksheet: function(e) {
        console.log(e)
        setTimeout(function () {
            if (store.data_obj.submit_ws == true) {
                return false;
            } else {
                store.data_obj.submit_ws = true;
            }
        }, 100);
        store.submit_worksheet(2);
    },

    submit_worksheet: async function(type) {
        if (store.data_obj.report_status != 4 && (JSON.stringify(store.data_obj.CODE) != store.data_obj.window_ws_data || type == 2)) {
            store.data_obj.window_ws_data = JSON.stringify(store.data_obj.CODE);
            store.data_obj.submit_ws_value = 2;
            store.data_obj.submit_ws = true;
            type = type == 2 ? type : 1;
            
            if (type == 2) {
                store.data_obj.show_spinner = true;
            }
            await axios
                .post(
                    process.env.VUE_APP_SITE_URL + "worksheet/worksheet-data", {
                    ws_status: store.data_obj.ws_status,
                    scope_details_id: store.data_obj.scope_detail_id,
                    CODE: store.data_obj.CODE,
                    type: type
                }, {
                    headers: {
                        Authorization: "Bearer " +
                            localStorage.getItem('plrlogintoken')
                    }
                }
            ).then(function(response) {
                console.log(response);
                if (type == 2) {
                    const element = document.getElementById('mid-div');
                    if (element) {
                        element.scrollTo({
                            top: 0,
                            behavior: 'smooth' 
                        });
                    }
                    store.is_show_message = true;
                    store.alert_class = "alert-success";
                    store.alert_message = "Worksheet Updated Successfully";
                }
                store.get_header_on_submit();
            })
            .catch(function (error) {
                console.log('Error while running API', error);
            });
            store.data_obj.show_spinner = false;
        }
    },

    get_header_on_submit: function() {
        services.getAuthRequest('worksheet/get-ws-last-update-data',{ scope_details_id: store.data_obj.scope_detail_id }).then((response) => {
            store.data_obj.worksheet_last_edit.last_edited_by = response.data.data.last_edited_by;
            store.data_obj.worksheet_last_edit.last_edited_on = response.data.data.last_edited_on;
            store.data_obj.submit_ws = false;
            store.data_obj.submit_ws_value = 1;
        })
        .catch(function (error) {
            console.log('Error while running API', error);
        });
    },
     
    set_add_comment_box: function(){
        store.data_obj.note_edit = false;
        store.data_obj.comment_edit = true;
        store.data_obj.note_type = "c";
        store.hide_ws_option_box();
        store.hide_link_to_a_note();
        store.data_obj.note_comment_text = "";
        store.set_note_comment_box();
    },

    set_note_comment_box: function () {
        store.data_obj.hide_cbox = false;
        if (store.data_obj.selected_element == null) {
            store.data_obj.selected_element = store.data_obj.current_element;
        }
        var rect = store.data_obj.selected_element.target.getBoundingClientRect();
        store.data_obj.box_left_c =
            rect.left +
            store.data_obj.current_element.target.offsetWidth -
            400 +
            "px";
        if (
            rect.left + store.data_obj.current_element.target.offsetWidth <
            400
        ) {
            store.data_obj.box_left_c = rect.left + "px";
        }
        store.data_obj.box_top_c = rect.top + store.data_obj.option_list_top + "px";
        store.data_obj.comnet_box_title = store.data_obj.selected_element.target.parentElement.firstChild.innerHTML;
        store.show_comment_box = true;
    },

    keymonitor: function (el) {
        var start = /@/gi; // @ Match
        var word = /@(\w+)/gi; //@abc Match
        store.data_obj.current_element = el;
        el.target.focus();
        var content = el.target.value; //Content Box Data
        var go = content.match(start); //Content Matching @
        var at_substr = content.substring(0, store.data_obj.selection_start + 1);
        var splited_str = at_substr.split(" ");
        var name = splited_str[splited_str.length - 1].match(word);
        var open_autosuggest = splited_str[splited_str.length - 1].indexOf("@") >= 0;
        store.data_obj.selection_start = el.target.selectionStart;
        if (go !== null && go.length > 0 && open_autosuggest) {
            store.data_obj.auto_suggest_opened = true;
            var rect = store.data_obj.current_element.target.getBoundingClientRect();
            store.data_obj.tag_left_pos = rect.left + "px";
            store.data_obj.tag_top_pos = rect.top + 120 + "px";

            store.data_obj.current_tag_el = el.target.id;
            store.get_all_staff_users(name);
            if (store.data_obj.staff_users.length === 0) {
                store.close_tag_box();
                store.data_obj.auto_suggest_opened = false;
                store.data_obj.asg_current_key = 0;
            }
        } else {
            store.data_obj.auto_suggest_opened = false;
            store.data_obj.asg_current_key = 0;
        }
    },

    get_all_staff_users: function (name) {
        services.getAuthRequest('worksheet/get-staff-users',{data_string:name}).then((response) => {
            store.data_obj.staff_users = response.data.data.staff_list;
        })
        .catch(function (error) {
            console.log('Error while running API ', error);
        });
    },

    close_tag_box: function () {
        store.data_obj.staff_users = [];
        store.data_obj.tag_left_pos = "-1000px";
        store.data_obj.tag_left_top = "-1000px";
    },

    get_worksheet_comments: function(fieldName, isSave) {
        let is_save = typeof isSave == "undefined" ? false : true;
        let field_name = typeof fieldName == "undefined" ? "" : fieldName;
        if (field_name != "" && !is_save) {
            store.data_obj.comments = store.groupBy(
                store.data_obj.full_comments,
                "field_id",
                field_name
            );
            return;
        }
        services.getAuthRequest('worksheet/comments',{"scope_details_id": store.data_obj.scope_detail_id, "field_name": field_name }).then((response) => {
            store.data_obj.tooltip["comments"] = response.data.data.comment_field_count;
            store.data_obj.full_comments = response.data.data.comment_data;

            if (field_name != "") {
                store.data_obj.comments = store.groupBy(
                    store.data_obj.full_comments,
                    "field_id",
                    field_name
                );
            } else {
                store.data_obj.comments = response.data.data.comment_data;
            }
            store.get_tooltip_count();
            for (let i in store.data_obj.edit_cancel){
                store.data_obj.edit_cancel[i] = false;
            }
        })
        .catch(function(error) {
            store.data_obj.comments = [];
            console.log(error);
        });
    },

    get_worksheet_notes: function(fieldName, isSave=false){
        let is_save = isSave == undefined ? false : isSave;
        let field_name = fieldName == undefined ? "" : fieldName;
        if (field_name != "" && !is_save) {
            store.data_obj.notes = store.groupBy(store.data_obj.total_notes, 'field_id', field_name);
            store.get_tooltip_count();
            return;
        }
        services.getAuthRequest('worksheet/notes',{"scope_details_id": store.data_obj.scope_detail_id, "field_name": field_name }).then((response) => {
            store.data_obj.total_notes = response.data.data.note_filed_data;
            store.data_obj.full_notes = response.data.data.note_data;
            store.data_obj.note_numbers = response.data.data.note_numbers;

            if (field_name != "") {
                store.data_obj.notes = store.groupBy(store.data_obj.total_notes, 'field_id', field_name);
            } else {
                store.data_obj.notes = response.data.data.note_data;
            }

            store.data_obj.tooltip['notes'] = response.data.data.note_field_count;
            store.get_tooltip_count();
            for (let i in store.data_obj.edit_cancel){
                store.data_obj.edit_cancel[i] = false;
            }
        }).catch(function(error) {
            store.data_obj.notes = [];
            console.log(error)
        });
    },
    
    close_note_comment_box: function () {
        store.close_tag_box();
        store.data_obj.box_left_c = "-1000px";
        store.data_obj.box_top_c = "-1000px";
        store.data_obj.hide_cbox = true;
        store.show_comment_box = false;
    },

    back_comment_box: function () {
        store.view_option_box(store.data_obj.selected_element);
        store.close_note_comment_box();
    },

    set_add_note_box: function () {
        store.data_obj.comment_edit = false;
        store.data_obj.note_edit = true;
        store.data_obj.note_type = "n";
        store.hide_ws_option_box();
        store.hide_link_to_a_note();
        store.set_note_comment_box();
    },

    groupBy: function (collection, property, field_value) {
        let i = 0,
            val,
            values = [],
            result = [];
        for (; i < collection.length; i++) {
            val = collection[i][property];
            if (field_value == val) {
                result.push(collection[i]);
            } else {
                values.push(val);
            }
        }
        return result;
    },

    get_tooltip_count: function () {
        var elements_note_tooltip = document.getElementsByClassName("note_tooltip");
        var elements_note_number_tooltip = document.getElementsByClassName("note_number_tooltip");
        while (elements_note_tooltip.length > 0) {
            elements_note_tooltip[0].parentNode.removeChild(elements_note_tooltip[0]);
        }

        while (elements_note_number_tooltip.length > 0) {
            elements_note_number_tooltip[0].parentNode.removeChild(elements_note_number_tooltip[0]);
        }
        var elements = document.querySelectorAll("#ws_form input[type=text]");
        for (let i = 0, element;(element = elements[i++]);) {
            var str = element.name;
            var input_name = str.replace(/'/g, "");
            input_name = input_name.trim();
            var el_name = str.replace(/[[\]]+/g, ".");
            el_name = el_name.replace(/.\s*$/, "");
            var comment_tooltip_class = "comment_tooltip";
            var strParent = element.parentElement;
            if (strParent.classList.contains("divTableCell")) {
                comment_tooltip_class = "comment_tooltip_table";
            }

            var note_count = store.data_obj.tooltip["notes"] && typeof store.data_obj.tooltip["notes"][input_name] != "undefined" ? store.data_obj.tooltip["notes"][input_name] : 0;
            var note_id = store.data_obj.tooltip["notes"] && typeof store.data_obj.tooltip["notes"][input_name + "_note_id"] != "undefined" ? store.data_obj.tooltip["notes"][input_name + "_note_id"] : 0;
            var comment_id = store.data_obj.tooltip["comments"] && typeof store.data_obj.tooltip["comments"][input_name + "_comment_id"] != "undefined" ? store.data_obj.tooltip["comments"][input_name + "_comment_id"] : 0;
            var is_main_note = store.data_obj.tooltip["notes"] && typeof store.data_obj.tooltip["notes"][input_name + "_main_note"] != "undefined" ? store.data_obj.tooltip["notes"][input_name + "_main_note"] : 0;
            var comment_additional_class = input_name.replace(/[[\]']+/g, "");

            comment_tooltip_class = comment_tooltip_class + " " + comment_additional_class;
            var note_number_exist = false;
            var comment_div_exist = false;
            var note_number_div_exist = false;
            var descendents = element.parentNode.getElementsByTagName("DIV");
            var j, e;

            for (j = 0; j < descendents.length; ++j) {
                e = descendents[j];
                var get_class = e.className.split(" ");
                if (e.nodeName == "DIV") {
                    if (
                        e.className == "note_tooltip" ||
                        e.className == "note_tooltip_table"
                    ) {
                        //note_div_exist = true;
                        //note_div = e;
                    } else if (
                        get_class[0] == "comment_tooltip" ||
                        get_class[0] == "comment_tooltip_table"
                    ) {
                        comment_div_exist = true;
                        comment_div = e;
                    } else if (
                        e.className == "note_number_tooltip"
                    ) {
                        note_number_exist = true;
                        note_number_div = e;
                    }
                }
            }

            if (note_number_div_exist && note_count == 0) {
                note_number_div.className = "";
                note_number_div.innerHTML = "";
            }

            if (!note_number_exist) {
                var note_number_div = document.createElement("div");
                var conditional_class = is_main_note == 1 ? "draggable note_number_tooltip main_note" : "note_number_tooltip";
                note_number_div.className = conditional_class;
                if ( store.data_obj.CODE.title == "IFGC" || store.data_obj.CODE.title == "IPC" || strParent.classList.length > 1 ) {
                    note_number_div.setAttribute( "style", "bottom: -15px;");
                    note_number_div.setAttribute( "draggable", 'true');
                }
            }
            if (store.data_obj.parent_request_id == '0' && store.data_obj.report_status != "4") {
                note_number_div.innerHTML = note_count;
            } else {
                note_number_div.className = "note_number_tooltip_rereview main_note";
                note_number_div.innerHTML = note_count;
            }
            note_number_div.title = note_count;
            note_number_div.title = note_number_div.title.replace(/&nbsp;/gi, ' ');
            if (note_count != 0 && !note_number_div_exist) {
                element.parentNode.insertBefore(note_number_div,element);
                element.setAttribute('data-noteId',note_id);
                element.setAttribute('data-fieldId',input_name);
            }else{
                element.removeAttribute('data-noteId',note_id);
                element.removeAttribute('data-fieldId',input_name);
            }
            var comment_count = store.data_obj.tooltip["comments"] && typeof store.data_obj.tooltip["comments"][input_name] != "undefined" ? store.data_obj.tooltip["comments"][input_name] : 0;
            if (!comment_div_exist) {
                var comment_div = document.createElement("div");
                comment_div.className = comment_tooltip_class;
                if (strParent.classList.length > 1) {
                    comment_div.setAttribute("style", "left: 1px;");
                }
            }
            comment_div.innerHTML = comment_count;
            if (comment_count != 0 && store.data_obj.user_type !== "customer"){
                element.parentNode.insertBefore(comment_div,element);
                element.setAttribute('data-comentId',comment_id);
                element.setAttribute('data-commFieldId',input_name);
            }else {
                comment_div.innerHTML = "";
                comment_div.className = "";
                element.removeAttribute('data-comentId',comment_id);
                element.removeAttribute('data-commFieldId',input_name);
            }
        }
    },

    link_to_a_note: function () {
        store.hide_ws_option_box();
        store.data_obj.to_validate = false;
        store.data_obj.hide_ltn_box = false;
        if (store.data_obj.selected_element == null) {
            store.data_obj.selected_element = store.data_obj.current_element;
        }
        var rect = store.data_obj.selected_element.target.getBoundingClientRect();
        store.data_obj.ltn_box_left_c = rect.left + store.data_obj.current_element.target.offsetWidth - 400 + "px";
        if (rect.left + store.data_obj.current_element.target.offsetWidth < 400) {
            store.data_obj.ltn_box_left_c = rect.left + "px";
        }
        store.data_obj.ltn_box_top_c = rect.top + store.data_obj.option_list_top + "px";
        store.close_note_comment_box();
        store.data_obj.comnet_box_title = store.data_obj.selected_element.target.parentElement.firstChild.innerHTML;
    },

    close_ltn_box: function () {
        store.data_obj.ltn_box_left_c = "-1000px";
        store.data_obj.ltn_box_top_c = "-1000px";
        store.data_obj.hide_ltn_box = true;
    },

    get_occupant_load_data: function (index) {
        if (store.data_obj.calculate_status) {
            let floor_area = parseFloat(
                store.data_obj.CODE.occupant_needs_chapters_10__11__12.occupant_load_table[
                    index
                ].floor_area
            );

            let square_ft_person = parseFloat(
                store.data_obj.CODE.occupant_needs_chapters_10__11__12.occupant_load_table[
                    index
                ].person
            );

            let other_occ_load = parseFloat(store.data_obj.CODE.occupant_needs_chapters_10__11__12
                .occupant_load_table[index].other_occ_load);

            let occupant_load = store.calculate_occupant_load(
                floor_area,
                square_ft_person,
            );

            let other_occupant_load = store.calculate_other_occupant_load(
                occupant_load,
                other_occ_load
            );

            store.data_obj.CODE.occupant_needs_chapters_10__11__12.occupant_load_table[
                index
            ].occ_load = !isNaN(occupant_load) ? occupant_load.toFixed(2) : "";

            if (!isNaN(other_occupant_load)) {
                store.data_obj.CODE.occupant_needs_chapters_10__11__12.occupant_load_table[
                    index
                ].total = other_occupant_load.toFixed(2);

            } else {
                store.data_obj.CODE.occupant_needs_chapters_10__11__12.occupant_load_table[
                    index
                ].total = !isNaN(occupant_load) ? occupant_load.toFixed(2) : "";
            }

            if (other_occ_load) {
                store.check_occupant_load(index);
            }
        }
    },

    calculate_occupant_load: function (floor_area, square_ft_person) {
        return floor_area / square_ft_person;
    },

    calculate_other_occupant_load: function (occupant_load, other_occ_load) {
        return occupant_load + other_occ_load;
    },

    check_occupant_load: function (index) {
        if (store.data_obj.calculate_status) {
            let other_occu_load = parseFloat(
                store.data_obj.CODE.occupant_needs_chapters_10__11__12.occupant_load_table[
                    index
                ].other_occ_load
            );
            let occupant_load = parseFloat(
                store.data_obj.CODE.occupant_needs_chapters_10__11__12.occupant_load_table[
                    index
                ].occ_load
            );
            if (typeof occupant_load === "number") {
                let total =
                    other_occu_load > occupant_load
                        ? other_occu_load
                        : occupant_load;
                    store.data_obj.CODE.occupant_needs_chapters_10__11__12.occupant_load_table[
                    index
                ].total = !isNaN(total) ? total.toFixed(2) : "";
            }
        }
    },

    get_clause_description: function (el) {
        if ('customer' == store.reg_data.logged_user_type) {
            alert('To access this code section, please go to https://codes.ICCSafe.org/ and search for the respective digital code book, then access the code section from the chapter navigation.');
            return;
        }
        var clause_no = el.target.innerText.trim();
        var section_post_fix = "(.*?)_Sec" + clause_no + "$";
        var regex = new RegExp(section_post_fix);
        var before_last = '';
        var param_arr = [];
        if (el.target.getAttribute('data-secId')) {
            var id = el.target.getAttribute('data-secId');
            var index = id.indexOf('_');
            before_last = id.slice(0, id.lastIndexOf('_'));
            param_arr = [id, before_last, id.slice(0, index)];
        } else {
            store.data_obj.sections_arr.forEach(function (item) {
                let ids = item.match(regex);
                if (ids != null) {
                    var id = item;
                    var index = id.indexOf('_');
                    before_last = id.slice(0, id.lastIndexOf('_'));
                    param_arr = [id, before_last, id.slice(0, index)];
                    return;
                }
            });
        }
        store.data_obj.premium_data.premium_response = 'Loading...';
        if (param_arr.length > 0) {
            var bookId = (el.target.getAttribute('data-bookId')) ? el.target.getAttribute('data-bookId') : param_arr[2];
            var chapterId = (el.target.getAttribute('data-chapterId')) ? el.target.getAttribute('data-chapterId') : param_arr[1];
            var definitionId = (el.target.getAttribute('data-definitionId')) ? el.target.getAttribute('data-definitionId') : param_arr[0];
            if('ICCA117' == bookId){
                store.data_obj.premium_data.book_year = 2009;
            } else {
                store.data_obj.premium_data.book_year = bookId.split('C2').join('C 2').split(' ')[1];
            }
            //Code to check latest version
            if(typeof store.data_obj.book_version == 'undefined' || typeof store.data_obj.book_version[bookId] == 'undefined') {
                store.get_book_version_data(bookId,chapterId,definitionId);

            } else {
                var old_version = bookId;
                store.data_obj.premium_data.print_info = store.data_obj.book_version[bookId].print_info;
                store.data_obj.premium_data.book_name = store.data_obj.book_version[bookId].title;
                bookId += store.data_obj.book_version[bookId].version;
                chapterId = chapterId.replace(old_version, bookId);

                let key_url = definitionId.split('_');
                if(key_url[0] == 'IRC2015'){
                    let splitString = definitionId.split(/_(.*)/s);
                    store.data_obj.premium_data.sec_id = splitString[0]+'P3_'+splitString[1];
                }else if(key_url[0] == 'IBC2021' || key_url[0] == 'IMC2021' || key_url[0] == 'IPC2021' || key_url[0] == 'IECC2021' || key_url[0] == 'IEBC2021' || key_url[0] == 'IFGC2021' || key_url[0] == 'IFC2021'){
                    let splitString = definitionId.split(/_(.*)/s);
                    store.data_obj.premium_data.sec_id = splitString[0]+'P1_'+splitString[1];
                }else if(key_url[0] == 'IRC2021'){
                    let splitString = definitionId.split(/_(.*)/s);
                    store.data_obj.premium_data.sec_id = splitString[0]+'P2_'+splitString[1];
                }else{
                    store.data_obj.premium_data.sec_id = definitionId;
                }

                if(old_version != 'IFC2018'){
                    definitionId = definitionId.replace(old_version, bookId);
                }
                if(old_version == 'IRC2018'){
                    definitionId = definitionId.replace(bookId, old_version);
                }
                store.get_clause_data(definitionId);
            }
        } else {
            store.data_obj.premium_response = "No Record Found";
        }
    },

    get_book_version_data: async function (bookId, chapterId, definitionId) {
        let params = {
            "book_id": bookId
        }
        await services.getAuthRequest('worksheet/get-book-version-response', params).then((response) => {
            if (typeof response.data !== 'undefined' && response.data) {
                store.data_obj.book_version[bookId] = response.data.data.data;
                var old_version = bookId;
                store.data_obj.premium_data.print_info = store.data_obj.book_version[bookId].print_info;
                store.data_obj.premium_data.book_name  = store.data_obj.book_version[bookId].title;
                bookId += store.data_obj.book_version[bookId].version;
                chapterId = chapterId.replace(old_version, bookId);
                let key_url = definitionId.split('_');
                if(key_url[0] == 'IRC2015'){
                    let splitString = definitionId.split(/_(.*)/s);
                    store.data_obj.premium_data.sec_id = splitString[0]+'P3_'+splitString[1];
                }else if(key_url[0] == 'IBC2021' || key_url[0] == 'IMC2021' || key_url[0] == 'IPC2021' || key_url[0] == 'IECC2021' || key_url[0] == 'IEBC2021' || key_url[0] == 'IFGC2021' || key_url[0] == 'IFC2021'){
                    let splitString = definitionId.split(/_(.*)/s);
                    store.data_obj.premium_data.sec_id = splitString[0]+'P1_'+splitString[1];
                }else if(key_url[0] == 'IRC2021'){
                    let splitString = definitionId.split(/_(.*)/s);
                    store.data_obj.premium_data.sec_id = splitString[0]+'P2_'+splitString[1];
                }else{
                    store.data_obj.premium_data.sec_id = definitionId;
                }
                if(old_version != 'IFC2018'){
                    definitionId = definitionId.replace(old_version, bookId);
                }
                if(old_version == 'IRC2018'){
                    definitionId = definitionId.replace(bookId, old_version);
                }
                store.get_clause_data(definitionId);
            } else {
                store.data_obj.premium_data.premium_response = "No Record Found";
            }
        }).catch(function (error) {
            if(error.response.status === 404){
                alert("Book with this book code was not found.");
            }else{
                alert('Loading error, please wait for a few seconds for the page to load or manually reload the page.');
            }
        });
    },

    get_clause_data: async function (definitionId) {
        let url = store.data_obj.new_premium_api_url + '/' + store.data_obj.new_premium_api_version + '/content/' + definitionId + '?raw=1';
        store.data_obj.pdf_url = url;
        let params = {
            "url": url, "premiumtoken": localStorage.getItem('premiumtoken')
        };
        store.data_obj.show_spinner = true;
        await services.getAuthRequest('worksheet/get-premium-response', params).then((response_arr) => {
            let response = response_arr.data;
            var full_content = '';
            if(response.data.type == 'figure' && response.data.figure != ''){
                full_content = '<div class="premium_area">';
                full_content += '<img src="'+response.data.figure+'" />';
                if(response.data.content && response.data.content != ''){
                    full_content += response.data.content;
                }
                if(response.data.footnote && response.data.footnote != ''){
                    full_content += response.data.footnote;
                }
                full_content += '</div>';
                store.data_obj.premium_data.premium_response = full_content;
            }else if(response.data.type == 'definition'){
                full_content += '<div class="premium_area">';
                full_content += response.data.term+' '+response.data.definition;
                full_content += '</div>';
            }else if (typeof response.data !== 'undefined') {
                full_content = '<div class="premium_area">';
                if(response.data.type == 'table'){
                    full_content += '<h5 class="bold premium_title text-center">'+response.data.label+' '+response.data.ordinal+'</h5><h5 class="bold premium_title text-center"> '+response.data.title+'</h5>';
                }
                full_content += response.data.content;
                if(response.data.footnote && response.data.footnote != ''){
                    full_content += response.data.footnote;
                }
                if(response.data.children && response.data.children.length > 0){
                    let classes = '';
                    for(let i=0; i<response.data.children.length; i++){
                        if(response.data.children[i].type == 'table'){
                            classes = 'premium_title text-center';
                        }else{
                            classes = 'premium_title';
                        }
                        if(response.data.children[i].type == 'figure' && response.data.children[i].figure != ''){
                            full_content += '<div class="premium_inner_content"><strong class="premium_title text-center">'+response.data.children[i].ordinal+' '+response.data.children[i].title+'</strong>';
                            full_content += '<img src="'+response.data.children[i].figure+'"/>';
                            if(response.data.children[i].content && response.data.children[i].content != ''){
                                full_content += response.data.children[i].content;
                            }
                            if(response.data.children[i].footnote && response.data.children[i].footnote != ''){
                                full_content += response.data.children[i].footnote;
                            }
                            full_content += '</div>';
                        }else if(response.data.children[i].type == 'definition'){
                            full_content += '<div class="premium_inner_content">';
                            full_content += response.data.children[i].term+' '+response.data.children[i].definition;
                            full_content += '</div>';
                        }else{
                            full_content += '<div class="premium_inner_content">';

                            full_content += '<strong class="'+classes+'">'+response.data.children[i].label+' '+response.data.children[i].ordinal+' '+response.data.children[i].title+'</strong>';
                            full_content += response.data.children[i].content;
                            if(response.data.children[i].footnote && response.data.children[i].footnote != ''){
                                full_content += response.data.children[i].footnote;
                            }
                            full_content += '</div>';

                            if(response.data.children[i].children && response.data.children[i].children.length > 0){
                                for(let j=0; j<response.data.children[i].children.length; j++){
                                    if(response.data.children[i].children[j].type == 'table'){
                                        classes = 'premium_title text-center';
                                    }else{
                                        classes = 'premium_title';
                                    }
                                    if(response.data.children[i].children[j].type == 'figure' && response.data.children[i].children[j].figure != ''){
                                        full_content += '<div class="premium_inner_inner_content"><strong class="premium_title text-center">'+response.data.children[i].children[j].ordinal+' '+response.data.children[i].children[j].title+'</strong>';
                                        full_content += '<img src="'+response.data.children[i].children[j].figure+'" />';
                                        if(response.data.children[i].children[j].content && response.data.children[i].children[j].content != ''){
                                            full_content += response.data.children[i].children[j].content;
                                        }
                                        if(response.data.children[i].children[j].footnote && response.data.children[i].children[j].footnote != ''){
                                            full_content += response.data.children[i].children[j].footnote;
                                        }
                                        full_content += '<br></div>';

                                    }else if(response.data.children[i].children[j].type == 'definition'){
                                        full_content += '<div class="premium_inner_inner_content">';
                                        full_content += response.data.children[i].children[j].term+' '+response.data.children[i].children[j].definition;
                                        full_content += response.data.children[i].children[j].extended;
                                        full_content += '</div>';
                                    }else{
                                        full_content += '<div class="premium_inner_inner_content">';
                                        full_content += '<strong class="'+classes+'">'+response.data.children[i].children[j].label+' '+response.data.children[i].children[j].ordinal+' '+response.data.children[i].children[j].title+'</strong>';
                                        full_content += response.data.children[i].children[j].content;
                                        if(response.data.children[i].children[j].footnote && response.data.children[i].children[j].footnote != ''){
                                            full_content += response.data.children[i].children[j].footnote;
                                        }
                                        full_content += '</div>';
                                        if(response.data.children[i].children[j].children && response.data.children[i].children[j].children.length > 0){
                                            for(let k=0; k<response.data.children[i].children[j].children.length; k++){
                                                if(response.data.children[i].children[j].children[k].type == 'table'){
                                                    classes = 'premium_title text-center';
                                                }else{
                                                    classes = 'premium_title';
                                                }
                                                if(response.data.children[i].children[j].children[k].type == 'figure' && response.data.children[i].children[j].children[k].figure != ''){
                                                    full_content += '<div class="premium_inner_inner_content"><strong class="premium_title text-center">'+response.data.children[i].children[j].children[k].ordinal+' '+response.data.children[i].children[j].children[k].title+'</strong>';
                                                    full_content += '<img src="'+response.data.children[i].children[j].children[k].figure+'" />';
                                                    if(response.data.children[i].children[j].children[k].content && response.data.children[i].children[j].children[k].content != ''){
                                                        full_content += response.data.children[i].children[j].children[k].content;
                                                    }
                                                    if(response.data.children[i].children[j].children[k].footnote && response.data.children[i].children[j].children[k].footnote != ''){
                                                        full_content += response.data.children[i].children[j].children[k].footnote;
                                                    }
                                                    full_content += '<br></div>';
                                                }else if(response.data.children[i].children[j].children[k].type == 'definition'){
                                                    full_content += '<div class="premium_inner_inner_content">';
                                                    full_content += response.data.children[i].children[j].children[k].term+' '+response.data.children[i].children[j].children[k].definition;
                                                    full_content += response.data.children[i].children[j].children[k].extended;
                                                    full_content += '</div>';
                                                }else{
                                                    full_content += '<div class="premium_inner_inner_content">';
                                                    full_content += '<strong class="'+classes+'">'+response.data.children[i].children[j].children[k].label+' '+response.data.children[i].children[j].children[k].ordinal+' '+response.data.children[i].children[j].children[k].title+'</strong>';
                                                    full_content += response.data.children[i].children[j].children[k].content;
                                                    if(response.data.children[i].children[j].children[k].footnote && response.data.children[i].children[j].children[k].footnote != ''){
                                                        full_content += response.data.children[i].children[j].children[k].footnote;
                                                    }
                                                    full_content += '</div>';
                                                    if(response.data.children[i].children[j].children[k].children && response.data.children[i].children[j].children[k].children.length > 0){
                                                        for(let l=0; l<response.data.children[i].children[j].children[k].children.length; l++){
                                                            if(response.data.children[i].children[j].children[k].children[l].type == 'table'){
                                                                classes = 'premium_title text-center';
                                                            }else{
                                                                classes = 'premium_title';
                                                            }
                                                            if(response.data.children[i].children[j].children[k].children[l].type == 'figure' && response.data.children[i].children[j].children[k].children[l].figure != ''){
                                                                full_content += '<div class="premium_inner_inner_content"><strong class="premium_title text-center">'+response.data.children[i].children[j].children[k].children[l].ordinal+' '+response.data.children[i].children[j].children[k].children[l].title+'</strong>';
                                                                full_content += '<img src="'+response.data.children[i].children[j].children[k].children[l].figure+'" />';
                                                                if(response.data.children[i].children[j].children[k].children[l].content && response.data.children[i].children[j].children[k].children[l].content != ''){
                                                                    full_content += response.data.children[i].children[j].children[k].children[l].content;
                                                                }
                                                                if(response.data.children[i].children[j].children[k].children[l].footnote && response.data.children[i].children[j].children[k].children[l].footnote != ''){
                                                                    full_content += response.data.children[i].children[j].children[k].children[l].footnote;
                                                                }
                                                                full_content += '<br></div>';
                                                            }else if(response.data.children[i].children[j].children[k].children[l].type == 'definition'){
                                                                full_content += '<div class="premium_inner_inner_content">';
                                                                full_content += response.data.children[i].children[j].children[k].children[l].term+' '+response.data.children[i].children[j].children[k].children[l].definition;
                                                                full_content += response.data.children[i].children[j].children[k].children[l].extended;
                                                                full_content += '</div>';
                                                            }else{
                                                                full_content += '<div class="premium_inner_inner_content">';
                                                                full_content += '<strong class="'+classes+'">'+response.data.children[i].children[j].children[k].children[l].label+' '+response.data.children[i].children[j].children[k].children[l].ordinal+' '+response.data.children[i].children[j].children[k].children[l].title+'</strong>';
                                                                full_content += response.data.children[i].children[j].children[k].children[l].content;
                                                                if(response.data.children[i].children[j].children[k].children[l].footnote && response.data.children[i].children[j].children[k].children[l].footnote != ''){
                                                                    full_content += response.data.children[i].children[j].children[k].children[l].footnote;
                                                                }
                                                                full_content += '</div>';
                                                                if(response.data.children[i].children[j].children[k].children[l].children && response.data.children[i].children[j].children[k].children[l].children.length > 0){
                                                                    for(let m=0; m<response.data.children[i].children[j].children[k].children[l].children.length; m++){
                                                                        if(response.data.children[i].children[j].children[k].children[l].children[m].type == 'table'){
                                                                            classes = 'premium_title text-center';
                                                                        }else{
                                                                            classes = 'premium_title';
                                                                        }
                                                                        if(response.data.children[i].children[j].children[k].children[l].children[m].type == 'figure' && response.data.children[i].children[j].children[k].children[l].children[m].figure != ''){
                                                                            full_content += '<div class="premium_inner_inner_content"><strong class="premium_title text-center">'+response.data.children[i].children[j].children[k].children[l].children[m].ordinal+' '+response.data.children[i].children[j].children[k].children[l].children[m].title+'</strong>';
                                                                            full_content += '<img src="'+response.data.children[i].children[j].children[k].children[l].children[m].figure+'" />';
                                                                            if(response.data.children[i].children[j].children[k].children[l].children[m].content && response.data.children[i].children[j].children[k].children[l].children[m].content != ''){
                                                                                full_content += response.data.children[i].children[j].children[k].children[l].children[m].content;
                                                                            }
                                                                            if(response.data.children[i].children[j].children[k].children[l].children[m].footnote && response.data.children[i].children[j].children[k].children[l].children[m].footnote != ''){
                                                                                full_content += response.data.children[i].children[j].children[k].children[l].children[m].footnote;
                                                                            }
                                                                            full_content += '<br></div>';
                                                                        }else if(response.data.children[i].children[j].children[k].children[l].children[m].type == 'definition'){
                                                                            full_content += '<div class="premium_inner_inner_content">';
                                                                            full_content += response.data.children[i].children[j].children[k].children[l].children[m].term+' '+response.data.children[i].children[j].children[k].children[l].children[m].definition;
                                                                            full_content += response.data.children[i].children[j].children[k].children[l].children[m].extended;
                                                                            full_content += '</div>';
                                                                        }else{
                                                                            full_content += '<div class="premium_inner_inner_content">';
                                                                            full_content += '<strong class="'+classes+'">'+response.data.children[i].children[j].children[k].children[l].children[m].label+' '+response.data.children[i].children[j].children[k].children[l].children[m].ordinal+' '+response.data.children[i].children[j].children[k].children[l].children[m].title+'</strong>';
                                                                            full_content += response.data.children[i].children[j].children[k].children[l].children[m].content;
                                                                            if(response.data.children[i].children[j].children[k].children[l].children[m].footnote && response.data.children[i].children[j].children[k].children[l].children[m].footnote != ''){
                                                                                full_content += response.data.children[i].children[j].children[k].children[l].children[m].footnote;
                                                                            }
                                                                            full_content += '</div>';
                                                                            if(response.data.children[i].children[j].children[k].children[l].children[m].children && response.data.children[i].children[j].children[k].children[l].children[m].children.length > 0){
                                                                                for(let n=0; n<response.data.children[i].children[j].children[k].children[l].children[m].children.length; n++){
                                                                                    if(response.data.children[i].children[j].children[k].children[l].children[m].children[n].type == 'table'){
                                                                                        classes = 'premium_title text-center';
                                                                                    }else{
                                                                                        classes = 'premium_title';
                                                                                    }
                                                                                    if(response.data.children[i].children[j].children[k].children[l].children[m].children[n].type == 'figure' && response.data.children[i].children[j].children[k].children[l].children[m].children[n].figure != ''){
                                                                                        full_content += '<div class="premium_inner_inner_content"><strong class="premium_title text-center">'+response.data.children[i].children[j].children[k].children[l].children[m].children[n].ordinal+' '+response.data.children[i].children[j].children[k].children[l].children[m].children[n].title+'</strong>';
                                                                                        full_content += '<img src="'+response.data.children[i].children[j].children[k].children[l].children[m].children[n].figure+'" />';
                                                                                        if(response.data.children[i].children[j].children[k].children[l].children[m].children[n].content && response.data.children[i].children[j].children[k].children[l].children[m].children[n].content != ''){
                                                                                            full_content += response.data.children[i].children[j].children[k].children[l].children[m].children[n].content;
                                                                                        }
                                                                                        if(response.data.children[i].children[j].children[k].children[l].children[m].children[n].footnote && response.data.children[i].children[j].children[k].children[l].children[m].children[n].footnote != ''){
                                                                                            full_content += response.data.children[i].children[j].children[k].children[l].children[m].children[n].footnote;
                                                                                        }
                                                                                        full_content += '<br></div>';
                                                                                    }else if(response.data.children[i].children[j].children[k].children[l].children[m].children[n].type == 'definition'){
                                                                                        full_content += '<div class="premium_inner_inner_content">';
                                                                                        full_content += response.data.children[i].children[j].children[k].children[l].children[m].children[n].term+' '+response.data.children[i].children[j].children[k].children[l].definition;
                                                                                        full_content += response.data.children[i].children[j].children[k].children[l].children[m].children[n].extended;
                                                                                        full_content += '</div>';
                                                                                    }else{
                                                                                        full_content += '<div class="premium_inner_inner_content">';
                                                                                        full_content += '<strong class="'+classes+'">'+response.data.children[i].children[j].children[k].children[l].children[m].children[n].label+' '+response.data.children[i].children[j].children[k].children[l].children[m].children[n].ordinal+' '+response.data.children[i].children[j].children[k].children[l].children[m].children[n].title+'</strong>';
                                                                                        full_content += response.data.children[i].children[j].children[k].children[l].children[m].children[n].content;
                                                                                        if(response.data.children[i].children[j].children[k].children[l].children[m].children[n].footnote && response.data.children[i].children[j].children[k].children[l].children[m].children[n].footnote != ''){
                                                                                            full_content += response.data.children[i].children[j].children[k].children[l].children[m].children[n].footnote;
                                                                                        }
                                                                                        full_content += '</div>';

                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                full_content += '</div>';
                store.data_obj.premium_data.premium_response = full_content;
            } else {
                store.data_obj.premium_data.premium_response = "No Record Found";
            }
            store.data_obj.clause_popup = true;
        }).catch(function (error) {
            if(error.response.status === 404){
                alert("Book with this book code was not found.");
            }else{
                alert('Loading error, please wait for a few seconds for the page to load or manually reload the page.');
            }
        });
        store.data_obj.show_spinner = false;
    },

    get_premium_token: async function (el) {
        store.data_obj.show_spinner = true;
        await services.getAuthRequest('worksheet/get-premium-token').then((response) => {
            //set_cookie('premiumtoken', response.data.data.access_token);
            localStorage.setItem('premiumtoken', response.data.data.access_token)
            if (el) {
                store.get_clause_description(el);
            }
        }).catch(function (error) {
            console.log(error);
        });
        store.data_obj.show_spinner = false;
    },

    get_max_allowable_area_2015: function (index) {//alert(index);
        if (store.data_obj.calculate_status) {
            let building_area = parseFloat(
                store.data_obj.CODE.general_building_limitation[index].check_maximum_allowable_area.allowable_building_areaasubasub
            );

            let number_of_stories = parseFloat(
                store.data_obj.CODE.general_building_limitation[index].check_maximum_allowable_area
                    .number_of_stories_above_grade_plane_maximum_3ssubasub
            );

            let result = store.calculate_max_allowable_area(
                building_area,
                number_of_stories
            );

            store.data_obj.CODE.general_building_limitation[index].check_maximum_allowable_area_equal = !isNaN(
                result
            )
                ? result.toFixed(2)
                : "";
        }
    },

    get_allowable_area_2012: function (index) {
        if (store.data_obj.calculate_status) {
            let conversion_factor = store.data_obj.CODE
                .general_building_limitation[index]
                .check_maximum_allowable_area.conversion_factor;

            let tabular_area = store.data_obj.CODE
                .general_building_limitation[index]
                .check_maximum_allowable_area.tabular_area_itable_503i;

            let result = store.calculate_max_allowable_area(
                conversion_factor,
                tabular_area
            );
            store.data_obj.CODE.general_building_limitation[index].check_maximum_allowable_area_allowable_area_per_floor_equal.iftsup2supi = !isNaN(
                result
            )
                ? result.toFixed(2)
                : "";
        }
    },

    get_allowable_floor_area_2012: function (index) {
        if (store.data_obj.calculate_status) {
            let allowable_area_per_floor = parseFloat(
                store.data_obj.CODE.general_building_limitation[index]
                    .check_maximum_allowable_area
                    .allowable_area_per_floor_iasubasubi
            );

            let number_of_stories = parseFloat(
                store.data_obj.CODE.general_building_limitation[index]
                    .check_maximum_allowable_area.number_of_stories_brmaximum_3
            );

            let result = store.calculate_max_allowable_area(
                allowable_area_per_floor,
                number_of_stories
            );
            store.data_obj.CODE.general_building_limitation[index].check_maximum_allowable_area_allowable_floor_area_all_stories.iftsup2supi = !isNaN(
                result
            )
                ? result.toFixed(2)
                : "";
        }
    },

    get_determine_construction_type_2012: function (index) {
        if (store.data_obj.calculate_status) {
            let conversion_factor = store.data_obj.CODE.general_building_limitation[index].area_modifications_to_table_503.conversion_factor;
            let actual_building_area = store.data_obj.CODE.general_building_limitation[index].determine_construction_type.actual_building_area_iftisup2sup;
            let result = store.calculate_adjusted_building_area(
                conversion_factor,
                actual_building_area
            );
            store.data_obj.CODE.general_building_limitation[index].determine_construction_type.allowable_building_area_iftisup2sup = !isNaN(
                result
            )
                ? result.toFixed(2)
                : "";
        }
    },

    calculate_adjusted_building_area: function (
        conversion_factor,
        actual_building_area
    ) {
        if (conversion_factor > 0) {
            return actual_building_area / conversion_factor;
        }
        else {
            return 0;
        }
    },

    calculate_max_allowable_area: function (
        building_area,
        number_of_stories
    ) {
        return building_area * number_of_stories;
    },

    calculate_frontage_area: function (
        total_frontage,
        perimeter,
        width_open_space
    ) {
        let area = ((total_frontage / perimeter - 0.25) * width_open_space) / 30;
        return area;
    },

    calculate_allowable_floor_area: function () {
        if (store.data_obj.calculate_status) {
            let allowable_area_per_floor = store.data_obj.CODE
                .general_building_limitations_chapters_5_6
                .check_maximum_allowable_area.allowable_area_per_floor_iasubasubi;

            let number_of_stories = store.data_obj.CODE
                .general_building_limitations_chapters_5_6
                .check_maximum_allowable_area.number_of_stories_brmaximum_3;

            let floor_area = allowable_area_per_floor * number_of_stories;

            store.data_obj.CODE.general_building_limitations_chapters_5_6.check_maximum_allowable_area_allowable_floor_area_all_stories.iftsup2supi = floor_area.toFixed(
                2
            );
        }
    },

    calculate_area_per_floor: function () {
        if (store.data_obj.calculate_status) {
            var conversion_factor = store.data_obj.CODE
                .general_building_limitations_chapters_5_6
                .check_maximum_allowable_area.conversion_factor;
            var tabular_area = store.data_obj.CODE
                .general_building_limitations_chapters_5_6
                .check_maximum_allowable_area.tabular_area_itable_503i;
            var allowable_area_per_floor = conversion_factor * tabular_area;

            store.data_obj.CODE.general_building_limitations_chapters_5_6.check_maximum_allowable_area_allowable_area_per_floor_equal.iftsup2supi = allowable_area_per_floor.toFixed(
                2
            );
        }
    },

    calculate_area_modification: function (lf, ls) {
        let At = parseFloat(
            store.data_obj.CODE.general_building_limitations_chapters_5_6
                .area_modifications_to_table_503
                .allowable_tabular_area__iasubtsub_table_503i
        );

        return lf + ls + At;
    },

    calculate_determine_contruction: function (At, NS, Lf) {
        return At + NS * Lf;
    },

    handle_scroll: function () {
        store.data_obj.scrolled = window.scrollY > 55;
        if (
            store.data_obj.current_element != null &&
            store.data_obj.hide_dbox === false
        ) {
            let rect = store.data_obj.current_element.target.getBoundingClientRect();
            store.data_obj.box_left =
                rect.left +
                store.data_obj.current_element.target.clientWidth -
                document.getElementById("wp_auto_options_id")
                    .clientWidth +
                "px";
            if (
                rect.left +
                store.data_obj.current_element.target.clientWidth <
                document.getElementById("wp_auto_options_id")
                    .clientWidth
            ) {
                store.data_obj.box_left = rect.left + "px";
            }
            store.data_obj.box_top =
                rect.top + store.data_obj.option_list_top + "px";
        }
        if (
            store.data_obj.selected_element != null &&
            store.data_obj.hide_cbox === false
        ) {
            let rect = store.data_obj.selected_element.target.getBoundingClientRect();
            store.data_obj.box_left_c =
                rect.left +
                store.data_obj.current_element.target.offsetWidth -
                400 +
                "px";
            if (
                rect.left +
                store.data_obj.current_element.target.offsetWidth <
                400
            ) {
                store.data_obj.box_left_c = rect.left + "px";
            }
            store.data_obj.box_top_c =
                rect.top + store.data_obj.option_list_top + "px";
                store.data_obj.comment_text = "no_change";
        }

        if (
            store.data_obj.selected_element != null &&
            store.data_obj.hide_ltn_box === false
        ) {
            let rect = store.data_obj.selected_element.target.getBoundingClientRect();
            store.data_obj.ltn_box_left_c =
                rect.left +
                store.data_obj.current_element.target.clientWidth -
                400 +
                "px";
            if (
                rect.left +
                store.data_obj.current_element.target.clientWidth <
                400
            ) {
                store.data_obj.ltn_box_left_c = rect.left + "px";
            }
            store.data_obj.ltn_box_top_c =
                rect.top + store.data_obj.option_list_top + "px";
                store.data_obj.comment_text = "no_change";
        }
    },

    handle_arrow_options: function (e) {
        if(e != undefined){
            if (store.data_obj.auto_suggest_opened) {
                var staff_user_length = store.data_obj.staff_users.length;
                if (e.keyCode === 40) {
                    e.preventDefault();
                    e.stopPropagation();
                    store.data_obj.asg_current_key++;
                    if (store.data_obj.asg_current_key > staff_user_length - 1) {
                        store.data_obj.asg_current_key = 0;
                    }
                }
                if (e.keyCode === 38) {
                    e.preventDefault();
                    e.stopPropagation();
                    store.data_obj.asg_current_key--;
                    if (store.data_obj.asg_current_key < 0) {
                        store.data_obj.asg_current_key = staff_user_length - 1;
                    }
                }
                if (e.keyCode === 13) {
                    e.preventDefault();
                    e.stopPropagation();
                    if (
                        typeof store.data_obj.staff_users[
                            store.data_obj.asg_current_key
                        ] !== "undefined"
                    ) {
                        store.mention_staff(
                            store.data_obj.staff_users[
                                store.data_obj.asg_current_key
                            ].display_name
                        );
                    }

                    store.data_obj.auto_suggest_opened = false;
                    store.data_obj.asg_current_key = 0;
                }
            }

            if (store.data_obj.current_element !== null) {
                if (e.keyCode === 38) {
                    store.data_obj.selected_ws_op--;
                }
                if (e.keyCode === 40) {
                    store.data_obj.selected_ws_op++;
                }
                if (store.data_obj.selected_ws_op == 10) {
                    store.data_obj.selected_ws_op = 0;
                }
                if (store.data_obj.selected_ws_op < 0) {
                    store.data_obj.selected_ws_op = 9;
                }
                var keys = Object.keys(store.s_options);
                store.data_obj.selected_ws_key = keys[store.data_obj.selected_ws_op];
                if (e.keyCode === 13) {
                    if (store.data_obj.selected_ws_op < 7) {
                        if (store.data_obj.hide_dbox === false) {
                            store.set_input_value(store.data_obj.selected_ws_key);
                        }
                    } else {
                        if (store.data_obj.hide_dbox === false) {
                            if (store.data_obj.selected_ws_op === 7) {
                                store.set_add_comment_box();
                            } else if (this.selected_ws_op === 8) {
                                store.set_add_note_box();
                            } else if (this.selected_ws_op === 9) {
                                store.link_to_a_note();
                            }
                        }
                    }
                }
                if (
                    e.ctrlKey === true &&
                    (e.keyCode === 49 || e.keyCode === 97)
                ) {
                    e.preventDefault();
                    e.stopPropagation();
                    store.set_add_comment_box();
                }
                if (
                    e.ctrlKey === true &&
                    (e.keyCode === 50 || e.keyCode === 98)
                ) {
                    e.preventDefault();
                    e.stopPropagation();
                    store.set_add_note_box();
                }
                if (
                    e.ctrlKey === true &&
                    (e.keyCode === 51 || e.keyCode === 99)
                ) {
                    e.preventDefault();
                    e.stopPropagation();
                    store.link_to_a_note();
                }
            }
        }
    },

    mention_staff: function (staff) {
        var el = document.getElementById(store.data_obj.current_tag_el);
        var start = /@/gi; // @ Match
        var word = /@(\w+)/gi; //@abc Match
        var content = el.value; //Content Box Data
        var go = content.match(start); //Content Matching @
        var name = content.match(word); //Content Matching @abc
        var end_string = content.substring(
            store.data_obj.selection_start
        );
        var at_substr = content.substring(
            0,
            store.data_obj.selection_start
        );
        var splited_str = at_substr.split(" ");
        var last_string = splited_str[splited_str.length - 1].split(
            "@"
        );
        splited_str[splited_str.length - 1] =
            last_string[0] + staff;
        var newstring = splited_str.join(" ");
        var new_content_value = newstring + " " + end_string;
        var dataString = name;
        console.log('dataString and go', dataString, go)
        document.getElementById(
            store.data_obj.current_tag_el
        ).value = new_content_value;
        document.getElementById(store.data_obj.current_tag_el).focus();
        store.close_tag_box();
    },

    handle_body_click: function (e) {
        if(e != undefined){
            if (
                !(
                    e.target.nodeName == "INPUT" ||
                    e.target.classList.contains("ws_auto_op") ||
                    e.target.classList.contains("ws_no_click") ||
                    e.target.classList.contains("note_comment_text")
                )
            ) {
                store.hide_ws_option_box();
            }
        }
    },

    refresh_ws: function (e) {
        if(e != undefined){
            if (e.keyCode === 13) {
                return false;
            } else {
                if (store.showSetFeeModal != true) {
                    // this.submit_worksheet(4);
                }
            }
        }
    },

    wp_auto_options_scroll: function (e) {
        var myElement = document.getElementById("mid-div");
        var cursorY = window.Event ?
            e.pageY :
            e.clientY +
            (document.documentElement.scrollTop ?
                document.documentElement.scrollTop :
                document.body.scrollTop);
        if (
            typeof e.deltaY == "undefined" &&
            window.screen.height - cursorY < 300
        ) {
            myElement.scrollTop += 50;
        } else {
            myElement.scrollTop += e.deltaY;
        }
    },

    get_area_modification_data: function (index) {
        if (store.data_obj.calculate_status) {
            let lf = parseFloat(
                store.data_obj.CODE.general_building_limitation[index]
                    .area_modifications_to_table_503
                    .area_increase_factor_due_to_frontage_iisubfsub_506_2i
            );

            let ls = parseFloat(
                store.data_obj.CODE.general_building_limitation[index]
                    .area_modifications_to_table_503
                    .area_increase_factor_due_to_automatic_sprinklers__iisubssub_506_3i
            );

            let conversion_factor = store.calculate_area_modification(lf, ls);

            store.data_obj.CODE.general_building_limitation[index].area_modifications_to_table_503.conversion_factor = !isNaN(
                conversion_factor
            )
                ? conversion_factor.toFixed(2)
                : "";
        }
    },

    getWorksheetHeader: async() => {
        let scope_detail_id = store.getParameterByName('id');
        await services.getAuthRequest('worksheet/get-worksheet-header-data', {scope_details_id: scope_detail_id}).then((response) => {
            let header_info = response.data.data;
            store.current_plr_id = header_info.plr_number;
            store.data_obj.worksheet_title = header_info.worksheet_title;
            store.data_obj.user_type = header_info.logged_user_type;
            store.data_obj.report_status = header_info.report_status;
            store.data_obj.plr_status = header_info.plr_status;
            store.data_obj.ws_status = header_info.numeric_ws_status;
            store.data_obj.plr_id = header_info.plr_number;
            store.data_obj.worksheet_last_edit = header_info;
            store.data_obj.parent_request_id = header_info.parent_request_id;
            store.data_obj.request_id_pdf = header_info.request_id_pdf;
            store.data_obj.pending_fee_quote_approval = header_info.parent_request_id == 0 && header_info.fee_quote_requested !== '0' ? header_info.pending_fee_quote_approval : false;
            if (header_info.calculate_status == 'true') {
                store.data_obj.calculate_status = true;
            } else {
                store.data_obj.calculate_status = false;
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    },

    calculateAllIBC2012: function () {
        for (let i = 0; i < store.data_obj.CODE.occupant_needs_chapters_10__11__12.occupant_load_table.length; i++) {
            store.get_occupant_load_data(i);
            store.check_occupant_load(i);
        }
        for (let i = 0; i < store.data_obj.CODE.general_building_limitation.length; i++) {
            store.get_allowable_area_2012(i);
            store.get_allowable_floor_area_2012(i);
            store.get_area_modification_data(i);
            store.get_frontage_data_2012(i);
            store.get_determine_construction_type_2012(i);
        }
    },

    calculateAllIBC2015: function () {
        for (let i = 0; i < store.data_obj.CODE.occupant_needs_chapters_10__11__12.occupant_load_table.length; i++) {
            store.get_occupant_load_data(i);
            store.check_occupant_load(i);
        }
        for (let i = 0; i < store.data_obj.CODE.general_building_limitation.length; i++) {
            store.get_construction_type_data_2015(i);
            store.get_max_allowable_area_2015(i);
            store.get_frontage_data_2015(i);
        }
    },

    calculateAllIBC2018: function () {
        for (let i = 0; i < store.data_obj.CODE.occupant_needs_chapters_10__11__12.occupant_load_table.length; i++) {
            store.get_occupant_load_data(i);
            store.check_occupant_load(i);
        }
        for (let i = 0; i < store.data_obj.CODE.general_building_limitation.length; i++) {
            store.get_construction_type_data_2015(i);
            store.get_max_allowable_area_2015(i);
            store.get_frontage_data_2015(i);
        }
    },

    calculateAllIBC2021: function () {
        for (let i = 0; i < store.data_obj.CODE.occupant_needs_chapters_10__11__12.occupant_load_table.length; i++) {
            store.get_occupant_load_data(i);
            store.check_occupant_load(i);
        }
        for (let i = 0; i < store.data_obj.CODE.general_building_limitation.length; i++) {
            store.get_construction_type_data_2015(i);
            store.get_max_allowable_area_2015(i);
            store.get_frontage_data_2015(i);
        }
    },

    get_frontage_data_2012: function (index) {
        if (store.data_obj.calculate_status) {
            let total_frontage = parseFloat(
                store.data_obj.CODE.general_building_limitation[index]
                    .area_modifications_to_table_503.total_frontage_ifi_ft_
            );
            let perimeter = parseFloat(
                store.data_obj.CODE.general_building_limitation[index]
                    .area_modifications_to_table_503.perimeter_ipi_ft_
            );
            let width_open_space = parseFloat(
                store.data_obj.CODE.general_building_limitation[index]
                    .area_modifications_to_table_503.width_of_open_space_iwi_
            );
            let area = store.calculate_frontage_area(
                total_frontage,
                perimeter,
                width_open_space
            );
            store.data_obj.CODE.general_building_limitation[index].area_modifications_to_table_503.area_increase_factor_due_to_frontage__iisubfsubi_506_2_ = !isNaN(
                area
            )
                ? area.toFixed(2)
                : "";
        }
    },

    get_construction_type_data_2015: function (index) {
        if (store.data_obj.calculate_status) {
            let tabular_allowance_area = parseFloat(
                store.data_obj.CODE.general_building_limitation[index].determine_construction_type
                    .tabular_allowance_area_ia_isubtsub_iftisup2sup
            );

            let tabular_allowance_area_nonsprinklered = parseFloat(
                store.data_obj.CODE.general_building_limitation[index].determine_construction_type
                    .tabular_allowance_area_for_nonsprinklered_buildings_ns_iftisup2sup
            );

            let lf = parseFloat(
                store.data_obj.CODE.general_building_limitation[index].frontage_increase
                    .area_increase_factor_due_to_frontage__iisubfsubi_506_3_3_
            );

            if (lf) {
                let Aa = store.calculate_determine_contruction(
                    tabular_allowance_area,
                    tabular_allowance_area_nonsprinklered,
                    lf
                );

                store.data_obj.CODE.general_building_limitation[index].determine_construction_type.allowable_building_area_iftisup2sup = !isNaN(
                    Aa
                )
                    ? Aa.toFixed(2)
                    : "";
            } else {
                store.data_obj.CODE.general_building_limitation[index].determine_construction_type.allowable_building_area_iftisup2sup =
                    "";
            }
        }
    },

    get_frontage_data_2015: function (index) {
        if (store.data_obj.calculate_status) {
            let total_frontage = parseFloat(
                store.data_obj.CODE.general_building_limitation[index].frontage_increase.total_frontage_ifi_ft_
            );

            let perimeter = parseFloat(
                store.data_obj.CODE.general_building_limitation[index].frontage_increase.perimeter_ipi_ft_
            );


            let width_open_space = parseFloat(
                store.data_obj.CODE.general_building_limitation[index].frontage_increase.width_of_open_space_w_
            );


            let area = store.calculate_frontage_area(
                total_frontage,
                perimeter,
                width_open_space
            );

            store.data_obj.CODE.general_building_limitation[index].frontage_increase.area_increase_factor_due_to_frontage__iisubfsubi_506_3_3_ = !isNaN(
                area
            )
                ? area.toFixed(2)
                : "";
        }
    },

    update_cal_status: async function (cal_status) {
        let form_data = {"id": store.data_obj.scope_detail_id, "status": cal_status };
        let headers = { 
            "Authorization": "Bearer " + localStorage.getItem('plrlogintoken')
        };
        await services.updateCalStatus('worksheet/worksheet-calculate-data', form_data, headers).then((response) => {
            console.log(response);
        });
    },

    upPosition: function(index_id, name = [], parent_name=''){
        if( index_id != 0){
            var new_index_id = index_id-1;
            var new_data = name[index_id];
            var old_data = name[new_index_id];
            console.log("index_id", new_data, old_data, index_id, parent_name);
            store.setNotePosition(parent_name, index_id, new_index_id, new_data, old_data);
            name[new_index_id] = new_data;
            name[index_id] = old_data;
        }
    },

    downPosition: function(index_id, name = [], parent_name=''){
        var new_index_id = index_id+1;
        var new_data = name[index_id];
        var old_data = name[new_index_id];
        store.setNotePosition(parent_name, index_id, new_index_id, new_data, old_data);
        name[new_index_id] = new_data;
        name[index_id] = old_data;
    },

    setNotePosition: async function(parent_name, index_id, new_index_id, new_data, old_data){
        store.data_obj.show_spinner = true;
        let full_name = '';
        let new_full_name = '';
        var input_arr = parent_name.split('.');
        for(let i=0; i<input_arr.length; i++){
            if(i != 0){
                full_name += "["+input_arr[i]+"]";
                new_full_name += "['"+input_arr[i]+"']";
            } else{
                full_name += input_arr[i];
                new_full_name += input_arr[i];
            }
        }
        var new_name = '';
        var ele = '';
        var field_id = '';
        var note_id = '';
        var comment_id = '';
        var comment_field_id = '';
        let newPosition = [];
        let newCommentPosition = [];

        Object.keys(new_data).forEach(key => {
            if(typeof new_data[key] === 'object'){
                for(let inner_key in new_data[key]){
                    new_name = full_name + "["+index_id+"]" + "["+key+"]" + "["+inner_key+"]";
                    ele = document.getElementsByName(new_name)[0];
                    if(typeof ele != 'undefined'){
                        field_id = ele.getAttribute('data-fieldId');
                        note_id = ele.getAttribute('data-noteId');
                        comment_field_id = ele.getAttribute('data-commFieldId');
                        comment_id = ele.getAttribute('data-comentid');
                        if(field_id && note_id){
                            let data = {
                                'field_id':field_id,
                                'note_id':note_id,
                                'new_field_id':new_full_name + "['"+new_index_id+"']" + "['"+key+"']" + "['"+inner_key+"']",
                            }
                            newPosition.push(data);
                        }
                        if(comment_id){
                            let data1 = {
                                'field_id':comment_field_id,
                                'comment_id':comment_id,
                                'new_field_id':new_full_name + "['"+new_index_id+"']" + "['"+key+"']" + "['"+inner_key+"']",
                            }
                            newCommentPosition.push(data1);
                        }
                    }
                }
            }else{
                new_name = full_name + "["+index_id+"]" + "["+key+"]";
                ele = document.getElementsByName(new_name)[0];
                if(typeof ele != 'undefined'){
                    field_id = ele.getAttribute('data-fieldId');
                    note_id = ele.getAttribute('data-noteId');
                    comment_field_id = ele.getAttribute('data-commFieldId');
                    comment_id = ele.getAttribute('data-comentid');
                    if(field_id && note_id){
                        let data = {
                            'field_id':field_id,
                            'note_id':note_id,
                            'new_field_id':new_full_name + "['"+new_index_id+"']" + "['"+key+"']",
                        }
                        newPosition.push(data);
                    }
                    if(comment_id){
                        let data1 = {
                            'field_id':comment_field_id,
                            'comment_id':comment_id,
                            'new_field_id':new_full_name + "['"+new_index_id+"']" + "['"+key+"']",
                        }
                        newCommentPosition.push(data1);
                    }
                }
            }
        });

        var old_name = '';
        var ele1 = '';
        var old_field_id = '';
        var old_note_id = '';
        var old_comment_id = '';
        var old_comment_field_id = '';

        Object.keys(old_data).forEach(old_key => {
            if(typeof old_data[old_key] === 'object'){
                for(let inner_old_key in old_data[old_key]){
                    old_name = full_name + "["+new_index_id+"]" + "["+old_key+"]" + "["+inner_old_key+"]";
                    ele1 = document.getElementsByName(old_name)[0];
                    if(typeof ele1 != 'undefined'){
                        old_field_id = ele1.getAttribute('data-fieldId');
                        old_note_id = ele1.getAttribute('data-noteId');
                        old_comment_field_id = ele1.getAttribute('data-commFieldId');
                        old_comment_id = ele1.getAttribute('data-comentid');
                        if(old_field_id && old_note_id){
                            let old_data = {
                                'field_id':old_field_id,
                                'note_id':old_note_id,
                                'new_field_id':new_full_name + "['"+index_id+"']" + "['"+old_key+"']" + "['"+inner_old_key+"']",
                            }
                            newPosition.push(old_data);
                        }
                        if(old_comment_id){
                            let data1 = {
                                'field_id':old_comment_field_id,
                                'comment_id':old_comment_id,
                                'new_field_id':new_full_name + "['"+index_id+"']" + "['"+old_key+"']"  + "['"+inner_old_key+"']",
                            }
                            newCommentPosition.push(data1);
                        }
                    }
                }

            }else{
                old_name = full_name + "["+new_index_id+"]" + "["+old_key+"]";
                ele1 = document.getElementsByName(old_name)[0];
                if(typeof ele1 != 'undefined'){
                    old_field_id = ele1.getAttribute('data-fieldId');
                    old_note_id = ele1.getAttribute('data-noteId');
                    old_comment_field_id = ele1.getAttribute('data-commFieldId');
                    old_comment_id = ele1.getAttribute('data-comentid');
                    if(old_field_id && old_note_id){
                        let old_data = {
                            'field_id':old_field_id,
                            'note_id':old_note_id,
                            'new_field_id':new_full_name + "['"+index_id+"']" + "['"+old_key+"']",
                        }
                        newPosition.push(old_data);
                    }
                    if(old_comment_id){
                        let data1 = {
                            'field_id':old_comment_field_id,
                            'comment_id':old_comment_id,
                            'new_field_id':new_full_name + "['"+index_id+"']" + "['"+old_key+"']",
                        }
                        newCommentPosition.push(data1);
                    }
                }
            }
        });
        
        let form_data = {
            id: store.data_obj.scope_detail_id,
            note_data: newPosition,
            comment_data: newCommentPosition
        }
        await services.postAuthRequest('worksheet/update-notes', form_data ).then((response) => {
            console.log(response);
            store.get_worksheet_notes();
            store.get_worksheet_comments();
        })
        .catch(function(error) {
            console.log('Error while running API ', error.response);
        });
        store.data_obj.show_spinner = false;
            
    },

    isNumeric: function (n) {
        if (n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        } else {
            return true;
        }
    },

    signalChange: function ($event) {
        if (!store.isNumeric($event.target.value) && store.data_obj.calculate_status) {
            if ($event.key != 'Delete' && $event.key != 'Backspace') {
                store.data_obj.show_wrong_box = true;
            }
        }
    },

    beforeunloadEvent: function(){
        alert("Loading");
    },

    report_obj: {
        expand_details: false,
        initail_window_height: window.innerHeight,
        center_panle_height: window.innerHeight - 355,
        report_notes: [],
        files: [],
        init_notes:'',
        parent_request_id: '',
        plr_number: '',
        report_notes_new:'',
        report_notes_old: [],
        report_date:'loading..',
        note:{},
        drag:false,
        indexnew:0,
        scope_id:0,
        current_editor_id: '',
        allowed_ext : ['dxf', 'dwg', 'igs', 'iges', 'dgn', 'doc', 'docx', 'pdf'],
        dateselected:  moment(new Date()).format('YYYY-MM-DD'),
        selected_date: '',
        parent_plr_number: '',
        current_plr_number: '',
        doc_id:0,
        doc_file_name:'',
        show_delete_doc:false,
        show_confirm: false,
        show_worksheet_confirm: false,
        show_fee_message: false,
        isSuccess: false,
        isError: false,
        fee_message: '',
        show_msg: true,
        pending_fee_quote_approval: false,
        documents_detail: '',
        doc_filename: '',
        parent_doc_filename: '',
        confirm_delete_note: false,
        swap: false
    },

    isMobile: window.innerWidth <= 1024 ? true : false,
    timerModel: false,
    idle_timer: '0.00',

    validate_user: async function() {
        let data = {};
        services.validateUser('do/login/validate', localStorage.getItem('plrlogintoken'), data).then((response) => {
            if (response.status === 200) {
                console.log("Valid User")
            }else if (response.status === 203 && response.message_code === 'maintenance_mode') {
                location.href = 'https://maintenance.iccsafe.org';
            } else {
                localStorage.clear();
                router.push('/');
            }
        }).catch(function (error) {
            console.log("validation error", error);
            router.push('/');
        });
    }
});
